import React, { useContext, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, useHistory, Redirect } from "react-router-dom";
import ErrorFallBack from "./components/errorFallBack";
import ProfileList from "./components/profileList";
import ProfileSettings from "./components/profileSettings";
import Routing from "./components/routing";
import { SessionContext } from "./context/SessionContext";
import ProtectedRoute from "./secure/protectedRoute/ProtectedRoute";
import "./style/Main.scss";
import LandingPage from "./views/landingPage";
import LoginRedirect from "./views/loginRedirect/LoginRedirect";
import ProfileSelection from "./views/profileSelection";
import "moment/locale/tr";
import ChannelControl from "./components/videoAlert/ChannelControl";
import ChannelCoverage from "./components/videoAlert/ChannelCoverage";
import ChannelLocking from "./components/videoAlert/ChannelLocking";
import PictureInPicturePlayer from "./components/pictureInPicturePlayer";
import LoginPage from "./components/loginPage";
import SelectLoginPage from "./components/selectLoginPage";
import ForgotPasswordPage from "./components/forgotPasswordPage";
import ChatbotComponent from "./components/chatbotComponent/ChatBotComponent";

function App() {
  const { pictureInPicture, setHasLoggedIn, setProfile } =
    useContext(SessionContext);
  const [direction, setDirection] = useState("ltr");

  useEffect(() => {
    setHasLoggedIn(JSON.parse(localStorage.getItem("hasLoggedIn")));
    setProfile(JSON.parse(localStorage.getItem("profile")));
  }, []);

  useEffect(() => {
    const language = localStorage.getItem("language");

    if (language === "arb") {
      setDirection("rtl");
    } else {
      setDirection("ltr");
    }
  }, []);

  return (
    <div style={{ direction: direction }}>
      <ErrorBoundary FallbackComponent={ErrorFallBack}>
        <div>
          <div>
            <Route path="/" exact>
              <Redirect to="/login" />
            </Route>
            <Route path="/redirect" component={LoginRedirect} />
            <Route path="/landingpage" component={LandingPage} />
            <Route path="/select-login" component={SelectLoginPage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/forgot-password" component={ForgotPasswordPage} />
            <Route path="/dashboard" component={Routing} />
            <Route path={`/channelcontrol`} component={ChannelControl} />
            <Route path={`/channelcoverage`} component={ChannelCoverage} />
            <Route path={`/channellocking`} component={ChannelLocking} />
            <ProtectedRoute path="/profiles/:id">
              <ProfileList />
            </ProtectedRoute>
            <ProtectedRoute path="/profile/:profileCode">
              <ProfileSettings />
            </ProtectedRoute>
            <Route path="/selectprofile" component={ProfileSelection} />
          </div>
        </div>
        {pictureInPicture ? (
          <div
            className="pip-player"
            style={{
              display: pictureInPicture ? "none" : null,
              zIndex: "1000000"
            }}
          >
            <PictureInPicturePlayer />
          </div>
        ) : null}
        <ChatbotComponent />
      </ErrorBoundary>
    </div>
  );
}

export default App;
