// export const cookieDomain = ".tmp.tivibu.com.tr";
export const cookieDomain = "";

export const apiEndPoint = "http://cms.k2.atlastv.net:31380/api";

export const specialForMeId = 3;

export const specialForMeKidId = 9;

export const childMenuId = 5;

export const homeBannerAdConfig = {
  adress: "/22269014526/OTT_Desktop_Web_VOD_970x90",
  dimensions: [
    { width: 728, height: 90 },
    { width: 970, height: 90 }
  ]
};

export const tvGuideBannerAdConfig = {
  adress: "/22269014526/OTT_Desktop_Web_EPG_970x90",
  dimensions: [
    { width: 728, height: 90 },
    { width: 970, height: 90 }
  ]
};

export const pauseBannerAdConfig = {
  adress: "/22269014526/OTT_Desktop_Web_Pause_1560x630",
  dimensions: [
    { width: 970, height: 250 },
    { width: 1560, height: 630 }
  ]
};

export const appVersion = "0.71.0";

const prod = {
  cookieDomain: window.location.host.slice(
    window.location.host.indexOf("."),
    window.location.host.length
  )
};

const dev = {
  cookieDomain: ""
};

export const fastLoginSessionKillUrl =
  "https://kolaygiris.turktelekom.com.tr/kolaygiris/mcLogout?client_id=tivibuweb";

let cookieData = {};
export function setCookieData(data) {
  cookieData = data;
}

export function getCookieData() {
  return cookieData;
}

let collectAgent = null;
export function setCollectAgent(data) {
  collectAgent = data;
}

export function getColectAgent() {
  return collectAgent;
}

let ws = null;
export function setWs(data) {
  ws = data;
}

export function getWs() {
  return ws;
}

let isApiErrorPopupOpen = false;
export function setIsApiErrorPopupOpen(val) {
  isApiErrorPopupOpen = val;
}

export function getIsApiErrorPopupOpen() {
  return isApiErrorPopupOpen;
}

let handleKeyRef = null;
export function setHandleKeyRef(val) {
  handleKeyRef = val;
}

export function getHandleKeyRef() {
  return handleKeyRef;
}

let videoProfile = null;
export function setVideoProfile(data) {
  videoProfile = data;
}

export function getVideoProfile() {
  return videoProfile;
}

let paused = false;
export function setPltvPaused(data) {
  paused = data;
}

export function getPltvPaused() {
  return paused;
}

let timeUpdateIntervalId = null;
export function setTimeUpdateIntervalId(data) {
  timeUpdateIntervalId = data;
}

export function getTimeUpdateIntervalId() {
  return timeUpdateIntervalId;
}

let globalTrailerAudio = true;
export function setTrailerAudioOnGlobal(data) {
  globalTrailerAudio = data;
}

export function getTrailerAudioOnGlobal() {
  return globalTrailerAudio;
}

let codeList = [
  "0",
  "1", //search no data
  "2",
  "5",
  "10050205",
  "1015035",
  "1005015",
  "10020105",
  "10050305",
  "1019013",
  "-1",
  "undefined",
  "",
  null,
  "null",
  "1040323",
  undefined,
  "1017043" //search keyword have no data
];

export function checkReturncode(code) {
  let index = codeList.findIndex((item) => item === code);

  return index === -1;
}

export const ttProduct = {
  0.99: "12359",
  4.99: "12360",
  6.99: "12361",
  8.99: "12362",
  19.99: "12363",
  27.99: "12364"
};

export const defaultProfileAvatar = `${process.env.PUBLIC_URL}/profilePosters/default.png`;

export const config = process.env.NODE_ENV === "development" ? dev : prod;

export const changePlayerGradient = (isAdd) => {
  let el = document.getElementById("player-gradient");

  if (el) {
    if (isAdd) {
      el.style.zIndex = "10";
      el.style.opacity = "1";
    } else {
      el.style.zIndex = "-1";
      el.style.opacity = "0";
    }
  }
};
// Picture in picture dataset <--begin-->
let pipPlayUrl = null;
let pipAudio = null;
let pipVolumeLevel = null;
let pipCurrentTime = null;
let pipSubtitles = null;
let pipPoster = null;
let pipVod = null;
let pipSelectedEpisode = null;
let pipLiveui = null;
let pipIsRecord = null;
let pipIsPreview = null;
let pipSelectedSeason = null;
let whereFrom = null;
let isFromPip = null;
let isPltv = null;
let currentTimeIsFromPip = null;

export function setIsPltv(data) {
  isPltv = data;
}

export function setPipPlayUrl(data) {
  pipPlayUrl = data;
}

export function setPipAudio(data) {
  pipAudio = data;
}

export function setPipVolumeLevel(data) {
  pipVolumeLevel = data;
}

export function setPipCurrentTime(data) {
  pipCurrentTime = data;
}

export function setPipSubtitles(data) {
  pipSubtitles = data;
}

export function setPipPoster(data) {
  pipPoster = data;
}

export function setPipVod(data) {
  pipVod = data;
}

export function setPipSelectedEpisode(data) {
  pipSelectedEpisode = data;
}

export function setPipLiveui(data) {
  pipLiveui = data;
}

export function setPipIsRecord(data) {
  pipIsRecord = data;
}

export function setPipIsPreview(data) {
  pipIsPreview = data;
}

export function setPipSelectedSeason(data) {
  pipSelectedSeason = data;
}

export function setWhereFrom(data) {
  whereFrom = data;
}

export function setIsFromPip(data) {
  isFromPip = data;
}

export function setCurrentTimeIsFromPip(data) {
  currentTimeIsFromPip = data;
}

export function getIsPltv() {
  return isPltv;
}

export function getPipPlayUrl() {
  return pipPlayUrl;
}

export function getPipAudio() {
  return pipAudio;
}

export function getPipVolumeLevel() {
  return pipVolumeLevel;
}

export function getPipCurrentTime() {
  return pipCurrentTime;
}

export function getPipSubtitles() {
  return pipSubtitles;
}

export function getPipPoster() {
  return pipPoster;
}

export function getPipVod() {
  return pipVod;
}

export function getPipSelectedEpisode() {
  return pipSelectedEpisode;
}

export function getPipLiveui() {
  return pipLiveui;
}

export function getPipIsRecord() {
  return pipIsRecord;
}

export function getPipIsPreview() {
  return pipIsPreview;
}

export function getPipSelectedSeason() {
  return pipSelectedSeason;
}

export function getWhereFrom() {
  return whereFrom;
}

export function getIsFromPip() {
  return isFromPip;
}

export function getCurrentTimeIsFromPip() {
  return currentTimeIsFromPip;
}

export function nullifyPip() {
  pipPlayUrl = null;
  pipAudio = null;
  pipVolumeLevel = null;
  pipCurrentTime = null;
  whereFrom = null;
  pipSubtitles = null;
  pipPoster = null;
  pipVod = null;
  pipSelectedEpisode = null;
  pipLiveui = null;
  pipIsRecord = null;
  pipIsPreview = null;
  pipSelectedSeason = null;
}

// Picture in picture dataset  <--end-->
