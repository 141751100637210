import React, { useContext, useEffect, useRef, useState } from "react";
import videojs from "video.js";
import ReactDOM from "react-dom";
import localizationStrings from "../../statics/localization";
import { getVodPlayUrlById } from "../../api/vod/vod";
import TrackControl from "./playerOptions/TrackControl";
import RestartButton from "./playerOptions/RestartButton";
import QualityButton from "./playerOptions/QualityButton";
import AudioControl from "./playerOptions/AudioControl";
import PlayButton from "./playerOptions/PlayButton";
import { SessionContext } from "../../context/SessionContext";
import { useHistory, useLocation } from "react-router-dom";
import ExitButton from "./playerOptions/ExitButton";
import RecordButton from "./playerOptions/RecordButton";
import FullscreenButton from "./playerOptions/FullscreenButton";
import Durations from "./playerOptions/Durations";
import PlayerChannelList from "./playerOptions/PlayerChannelList";
import "video.js/dist/video-js.css";
import "videojs-contrib-eme";
import {
  addBookmark,
  getBookmarkOfContent,
  deleteBookmarkContent
} from "../../api/common/commonApi";
import "videojs-contrib-ads";
import "videojs-ima";
import PlayerBar from "./playerOptions/PlayerBar";
import Notification from "../notification";
import BannerAd from "../bannerAd/BannerAd";
import {
  changePlayerGradient,
  getColectAgent,
  getHandleKeyRef,
  getTimeUpdateIntervalId,
  getVideoProfile,
  pauseBannerAdConfig,
  setPltvPaused,
  setPipPlayUrl,
  setPipAudio,
  setPipVolumeLevel,
  setPipCurrentTime,
  setPipSubtitles,
  setWhereFrom
} from "../../statics/config";
import { initializeThumbnailSprite } from "./playerOptions/Thumbnail";
import NextEpisode from "./playerOptions/NextEpisode";
import { sendVideoComplated } from "../../api/vod/vod";
import { reportingEvent } from "../../statics/reportEventId";
import moment from "moment";
import { sendCustomBookmarkReport } from "../../utils/reporting/customReporting";
import RewindBackButton from "./playerOptions/RewindBackButton";
import RewindForwardButton from "./playerOptions/RewindForwardButton";
import PlayerInfo from "./playerOptions/PlayerInfo";
import BackLiveButton from "./playerOptions/BackLiveButton";
import PlaybackRate from "./playerOptions/PlaybackRate";
import { isFirefox, isSafari } from "react-device-detect";
import ChannelLocking from "../videoAlert/ChannelLocking";
import ChannelControl from "../videoAlert/ChannelControl";
import ChannelCoverage from "../videoAlert/ChannelCoverage";
import { addLastWatchedChannel } from "../../api/ltv/ltv";
import "videojs-contrib-dash";
import "dashjs";
import {
  destroyFingerPrint,
  hideFingerPrint,
  initFingerPrint,
  unHideFingerPrint
} from "../../utils/initFingerPrint";
import PictureInPictureButton from "./playerOptions/PictureInPictureButton";
import ProductsInContent from "./playerOptions/ProductsInContent";

const playerMap = { true: null, false: null };

const getVideoPlayer = (ref, options) => {
  if (!playerMap[options.liveui]) {
    playerMap[options.liveui] = videojs(ref, options);
  }
  return playerMap[options.liveui];
};

const VideoPlayer = ({
  options,
  src,
  vod,
  channel,
  setSelectedChannel,
  thumbnails,
  isRecord,
  closeRecordModal,
  error,
  myTvChannelProgram,
  bookmarkTime,
  setBookmarkTime,
  cuTvProgram,
  setIsLiveEdge,
  isTimeshiftEnable,
  showAd,
  closePlayer,
  isPltv,
  nextEpisode,
  selectedSeason,
  selectedEpisode,
  playNextEpisode,
  fromVodGrid,
  fromTvGuide,
  channelCdnCode,
  channels,
  genres,
  searchFilter,
  prevueCode,
  setCanWatch,
  setLockChannelCode,
  isPreview,
  setPltv,
  isCutvVod,
  poster,
  epgProgramId
}) => {
  const [player, setPlayer] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [hasRowEl, setHasRowEl] = useState(false);
  const [duration, setDuration] = useState(null);
  const [showNotification, setShowNotification] = useState(null);
  // const [showBannerAd, setShowBannerAd] = useState(true);
  const [pBookmarkTime, setpBookmarkTime] = useState(0);
  const [showBookmarkPopup, setShowBookmarkPopup] = useState(false);
  const [currentTime, setCurrentTime] = useState(null);
  const [showExitNotification, setShowExitNotification] = useState(null);
  const [showNotificationError, setShowNotificationError] = useState(false);
  const [playerError, setPlayerError] = useState(null);
  const [restartTv, setRestartTv] = useState(0);
  const [rewindBackTv, setRewindBackTv] = useState(0);
  const [isRenderChannellist, setIsRenderChannellist] = useState(false);
  const [textTracks, setTextTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const {
    userFrameConfig,
    myProgram,
    isRestartProgram,
    setIsRestartProgram,
    userInfo,
    profile,
    recordStatus,
    setIsClickedRecord,
    isClickedRecord,
    setSelectedCutvProgram,
    setSelectedGenre,
    selectedGenre,
    adminProfile,
    channelItems,
    setRecordStatus,
    programInfo,
    mesFingerPrintParam,
    setMesFingerPrintParam,
    pictureInPicture,
    setPictureInPicture
  } = useContext(SessionContext);
  let history = useHistory();
  let vjsPlayer = null;
  let timer;
  const bookmarkIntId = useRef(null);
  const intervalId = useRef(null);
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  window.googletag = window.googletag || { cmd: [] };
  const googletag = window["googletag"] || {};
  const hasPlayer = useRef(false);
  const hasIma = useRef(false);
  const continueClicked = useRef(false);
  const isBookmarkPopupOpen = useRef(false);
  const showBannerAd = useRef(false);
  const adSlot = useRef(null);
  const collectAgent = useRef(getColectAgent());
  const seekTimer = useRef(null);
  const reportIntervalId = useRef(null);
  const location = useLocation();
  const qualityLevels = useRef(null);
  const isFullscreen = useRef(false);
  const pltv = useRef(isPltv);
  const smptePlayer = useRef(null);
  const vodCaptions = useRef([]);
  const audioItems = useRef([]);
  const cuTvRef = useRef(null);
  pltv.current = isPltv;

  const fillFingerPrintParams = (fingerPrintInfo) => {
    console.log("fingerprint info: ", fingerPrintInfo);
    let fontTypes = fingerPrintInfo?.fonttype.split(",");
    let fontFamily = fontTypes[0].trim();
    let fontWeight = fontTypes[1].trim().toLowerCase();
    return {
      interval: parseInt(fingerPrintInfo?.interval),
      timeout: parseInt(fingerPrintInfo?.showtime),
      timeStamp: fingerPrintInfo?.timestamp,
      fontSize: parseInt(fingerPrintInfo?.fontsize),
      bottom: parseInt(fingerPrintInfo?.rangeY),
      left: parseInt(fingerPrintInfo?.rangeX),
      bgColor: `rgba(${fingerPrintInfo?.backcolor})`,
      color: `rgba(${fingerPrintInfo?.fontcolor})`,
      fontFamily: fontFamily,
      fontWeight: fontWeight
    };
  };

  /* console.log({ src }); */

  const callRenderProgressBar = (player) => {
    if (!!myTvChannelProgram?.programcode) {
      player.el().classList.add("program-player");
      ReactDOM.unmountComponentAtNode(
        document.getElementById(`player-bar-${options.liveui}`)
      );
    } else {
      if (!cuTvProgram) {
        player.el().classList.remove("program-player");
        renderProgressBar(player);
      } else {
        player.el().classList.add("program-player");
        ReactDOM.unmountComponentAtNode(
          document.getElementById(`player-bar-${options.liveui}`)
        );
      }
    }
  };

  const destroyPlayer = () => {
    if (
      isSafari &&
      playerMap[options.liveui].children_[0].webkitPresentationMode ===
        "picture-in-picture"
    ) {
      playerMap[options.liveui].children_[0].webkitSetPresentationMode(
        "inline"
      );
    }

    getTimeUpdateIntervalId() && clearInterval(getTimeUpdateIntervalId());
    hasPlayer.current = false;
    // playerMap[options.liveui].off();
    //destroySmptePlayer();
    playerMap[options.liveui].dispose();
    playerMap[options.liveui] = null;
    if (userInfo?.usertype === "1") {
      // unRegisterReportUtils();
    }

    setHasRowEl(false);
    isFullscreen.current = false;
    smptePlayer.current && smptePlayer.current.destroySmpte();
  };

  const destroySmptePlayer = () => {
    if (smptePlayer.current) {
      smptePlayer.current.setCurrentTextTrack(-1);
      smptePlayer.current.destroySmpte();
      smptePlayer.current = null;
    }
  };

  const createPlayer = () => {
    let videoEl = document.createElement("video");
    videoEl.classList.add("vjs-tt-player");
    videoEl.classList.add("video-js");
    if (options.liveui) {
      videoEl.classList.add("live-player");
    } else {
      videoEl.classList.add("vod-player");
    }
    videoEl.setAttribute("id", `video-player-${options.liveui}`);
    document
      .getElementById(`player-container-${options.liveui}`)
      .appendChild(videoEl);
    initPlayer();
  };

  function handleKeyPress(e) {
    switch (e.code) {
      case "ArrowRight":
        playerRef.current.currentTime(playerRef.current.currentTime() + 10);
        break;
      case "ArrowLeft":
        playerRef.current.currentTime(playerRef.current.currentTime() - 10);
        break;
      default:
        break;
    }
  }

  // DRM RELATED CONVERTER FUNCTIONS
  function uint8ArrayToString(array) {
    return String.fromCharCode.apply(null, new Uint16Array(array.buffer));
  }

  function base64EncodeUint8Array(input) {
    const keyStr =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let chr1;
    let chr2;
    let chr3;
    let enc1;
    let enc2;
    let enc3;
    let enc4;
    let i = 0;
    let output = "";

    while (i < input.length) {
      chr1 = input[i++];
      chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
      chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output +=
        keyStr.charAt(enc1) +
        keyStr.charAt(enc2) +
        keyStr.charAt(enc3) +
        keyStr.charAt(enc4);
    }

    return output;
  }

  function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  /**
   * Initialization function for videojs
   */
  const initPlayer = () => {
    if (src && src !== -1 && src !== -2) {
      if (userInfo?.usertype === "1")
        sendMediaReport(reportingEvent.E_MEDIA_INIT);

      if (!vjsPlayer)
        vjsPlayer = getVideoPlayer(`video-player-${options.liveui}`, {
          ...options,
          errorDisplay: false,
          userActions: {
            click: () => {
              if (!options.liveui) {
                if (vjsPlayer.paused()) {
                  vjsPlayer.play();
                } else {
                  vjsPlayer.pause();
                }
              }
            },
            doubleClick: false
          }
        });

      if (!hasPlayer.current) {
        if (options.liveui && (cuTvProgram || myTvChannelProgram)) {
        }
      }

      if (userInfo?.usertype === "1") {
        // if (hasPlayer.current) unRegisterReportUtils();
        // registerReportUtils({
        //   player: vjsPlayer,
        //   data: vod || channel || cuTvProgram || myTvChannelProgram,
        //   isLive: !!channel,
        //   isRecord: isRecord
        // });
      }

      if (src.includes(".m3u8")) {
        if (vod?.id === 1198) {
          vjsPlayer.src([
            {
              src: "https://cdn.bitmovin.com/content/assets/sintel/hls/playlist.m3u8",
              type: "application/x-mpegURL"
            }
          ]);
        } else {
          vjsPlayer.src([
            {
              src: src,
              type: "application/x-mpegURL"
            }
          ]);
        }
        // vjsPlayer.src([
        //   {
        //     src: "https://cdn.bitmovin.com/content/assets/sintel/hls/playlist.m3u8",
        //     //src: src,
        //     type: "application/x-mpegURL"
        //   }
        // ]);
      } else {
        vjsPlayer.src([
          {
            src: src,
            type: "video/mp4"
          }
        ]);
      }

      if (userInfo?.usertype === "1") {
        onPlayerError(vjsPlayer);
      }

      if (vod?.programcode || isRecord || fromTvGuide) {
        vjsPlayer.on("ended", () => {
          if (isRecord || fromTvGuide) {
            closeRecord();
          } else {
            goBackVodDetail(vjsPlayer.currentTime());
          }
        });
      }
      if (!!cuTvProgram) {
        vjsPlayer.on("ended", () => {
          setIsLiveEdge("2");
        });
      }

      if (vod?.id === 502) {
        initIma(vjsPlayer);
      }

      vjsPlayer.off("loadeddata");
      vjsPlayer.on("loadeddata", () => {
        audioItems.current = Array.from(vjsPlayer.audioTracks().tracks_);
        console.log(Array.from(vjsPlayer.textTracks().tracks_));
        vodCaptions.current = Array.from(vjsPlayer.textTracks().tracks_);

        let vodContinue = vod?.interactions.find(
          (interaction) => interaction.type === "VOD_WATCHED"
        );

        if (vodContinue) {
          vjsPlayer.currentTime(vodContinue?.lastDuration);
        }
        console.log(bookmarkTime, isPreview, "bookmarkTime");
        if (bookmarkTime != 0 && !!bookmarkTime) {
          vjsPlayer.currentTime(bookmarkTime);
        }

        !options.liveui && listenTimeUpdate(vjsPlayer);

        setDuration(secondsToHms(vjsPlayer.duration()));
        continueClicked.current = false;
        renderControlBar(vjsPlayer);
        if (options.liveui) {
          callRenderProgressBar(vjsPlayer);
        }

        if (!options.liveui) {
          vjsPlayer.on("pause", () => {
            setIsPaused(true);
          });

          vjsPlayer.on("play", () => {
            setIsPaused(false);
          });
        } else if (
          !isPltv ||
          cuTvProgram?.prevuecode ||
          myTvChannelProgram?.programcode
        ) {
          setIsPaused(false);
        }
      });
    }
    if (
      !options.liveui ||
      cuTvProgram?.prevuecode ||
      myTvChannelProgram?.programcode
    )
      document.addEventListener("keydown", handleKeyPress);

    setPlayer(vjsPlayer);
    playerRef.current = vjsPlayer;
  };

  const onPlayerError = (player) => {
    player.on("error", (e) => {
      let error = player.error();
      let errorCode;
      let drmErrorCode;
      let drmErrorMessage;
      let err = {};
      if (vod?.programcode) {
        drmErrorCode = "15097606";
        drmErrorMessage = localizationStrings.vodDrmErrorMessage;
      } else if (vod?.prevuecode || cuTvProgram?.prevuecode) {
        drmErrorCode = isRecord ? "15093606" : "15095606";
        drmErrorMessage = localizationStrings.vodDrmErrorMessage;
      } else if (channel?.channelcode) {
        drmErrorCode = isPltv ? "15096606" : "15098606";
        drmErrorMessage = isPltv
          ? localizationStrings.vodDrmErrorMessage
          : localizationStrings.channelDrmErrorMessage;
      }

      switch (error.code) {
        case 0:
          errorCode = "";
          err.message = error.message;
          break;
        case 1:
          errorCode = "";
          err.message = error.message;
          break;
        case 2:
          errorCode = "15097663"; //404 not found
          err.message = localizationStrings.contentNotFoundError;
          break;
        case 3:
          errorCode = channel?.channelcode ? "15098653" : "15097653"; //Video format error
          err.message = channel?.channelcode
            ? localizationStrings.videoFormatErrorChannel
            : localizationStrings.videoFormatErrorVod;
          break;
        case 4:
          errorCode = channel?.channelcode ? "15098656" : "15097656"; //manifest error
          err.message = channel?.channelcode
            ? localizationStrings.manifestChannelError
            : localizationStrings.manifestVodError;
          break;
        case 5:
          errorCode = drmErrorCode; //DRM error
          err.message = drmErrorMessage;
          break;
        default:
          break;
      }
      let eventTime = moment().format("yyyyMMDDHHmmss");
      err.code = errorCode;

      setPlayerError(err);
      setShowNotificationError(true);
      let reportObject = {
        eventtype: reportingEvent.E_MEDIA,
        eventid: reportingEvent.E_MEDIA_ERROR,
        sessionid: localStorage.getItem("sessionid"),
        eventtime: eventTime,
        actiontime: eventTime,
        playid: "0",
        result: errorCode,
        errormsg: error.message
      };

      collectAgent.current.logMedia(reportObject);
    });
  };

  const secondsToHms = (d) => {
    /*  console.log({ d }); */
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? ((h + "").length > 1 ? h : `0${h}`) : "00";
    var mDisplay = m > 0 ? ((m + "").length > 1 ? m : `0${m}`) : "00";
    var sDisplay = s > 0 ? ((s + "").length > 1 ? s : `0${s}`) : "00";

    return `${hDisplay}:${mDisplay}:${sDisplay}`;
  };

  const initIma = (player) => {
    let imaOptions = {
      adLabel: "",
      // disableAdControls: true,
      adTagUrl:
        "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/vmap_ad_samples&sz=640x480&cust_params=sample_ar%3Dpreonly&ciu_szs=300x250%2C728x90&gdfp_req=1&ad_rule=1&output=vmap&unviewed_position_start=1&env=vp&impl=s&correlator="
      //"https://pubads.g.doubleclick.net/gampad/ads?iu=/22269014526/OTT_Desktop_Preroll&description_url=https%3A%2F%2Fwww.tivibu.com.tr&tfcd=0&npa=0&sz=400x300%7C640x480&min_ad_duration=1000&max_ad_duration=30000&gdfp_req=1&output=vmap&unviewed_position_start=1&env=vp&correlator=12345&url=http%3A%2F%2Fwww.tivibu.com.tr&ad_rule=1"
      // "https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dskippablelinear&correlator="
      // "https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dlinearvpaid2js&correlator="
      // "https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dlinearvpaid2js&correlator="
      // "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/simid&description_url=https%3A%2F%2Fdevelopers.google.com%2Finteractive-media-ads&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator="
      // https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dskippablelinear&correlator=
    };
    if (!hasIma.current) {
      hasIma.current = true;
      player.ima(imaOptions);
      player.pause();
    }

    player.on("adsready", function () {
      player.ima.addEventListener(
        window.google.ima.AdEvent.Type.COMPLETE,
        function () {
          if (isBookmarkPopupOpen.current) {
            setTimeout(() => {
              player.pause();
            }, 100);
          }
        }
      );

      player.ima.addEventListener(
        window.google.ima.AdEvent.Type.SKIPPED,
        function () {
          if (player.muted() && localStorage.getItem("volume")) {
            player.muted(false);
          }
        }
      );

      player.ima.addEventListener(
        window.google.ima.AdEvent.Type.COMPLETE,
        function () {
          if (player.muted() && localStorage.getItem("volume")) {
            player.muted(false);
          }
        }
      );
    });
  };

  const listenTimeUpdate = (player) => {
    player.off("timeupdate");
    player.on("timeupdate", () => {
      setCurrentTime(secondsToHms(player.currentTime()));
      if (!!nextEpisode && !continueClicked.current) {
        if (player.duration() - 30 <= player.currentTime()) {
          renderNextEpisode(player);
          changePlayerGradient(true);
        }
        if (player.duration() === player.currentTime()) {
          unMountNextEpisodeComponent();
          playNextEpisode();
          changePlayerGradient(false);
        }
      }
    });
  };

  const showControlBarElements = () => {
    let controlBarEl = document.getElementById(
      `vod-player-row-${options.liveui}`
    );
    let durationEl = document.getElementById(`durations-${options.liveui}`);
    let playPauseEl = document.getElementById(`play-button-${options.liveui}`);

    document.getElementsByClassName("vjs-control-bar")[0].style.display =
      "flex";
    controlBarEl.style.display = "flex";
    durationEl.style.display = "flex";
    playPauseEl.style.display = "flex";
    // player.getChild("ControlBar").el().style.display = "block";
  };

  const initThumbnail = (player, thumbnails) => {
    initializeThumbnailSprite(player, {
      sprites: thumbnails
    });
  };

  const channelSelection = (item) => {
    history.push(`/dashboard/livetv/${item.channelcode}`);
    setSelectedChannel(item);
  };

  const myLogger = (key, value = "") => {
    console.log(`VideoPlayer ${key}: ${value} `);
  };

  const renderPlayer = (player) => {
    !hasRowEl && createElements(player);

    if (!options.liveui) {
      // renderPlayPauseButton(player);
      renderDurations(player);
      renderExitButton(player);
      renderClickableScreen();
      renderControlBar(player);
      renderProductsInContent(player);
      if (vod?.programcode) {
        // renderShareButton();
      }
    } else if (isSafari) {
      renderSmpteSubtitleBox();
    }
  };

  const renderControlBar = (player) => {
    ReactDOM.render(
      <div className="wrapper">
        {options?.liveui ? (
          <div className="control-buttons">
            <PlayButton
              player={player}
              isPaused={isPaused}
              setIsPaused={setIsPaused}
              goBack={goBackVodDetail}
              sendMediaReport={sendMediaReport}
              isPltv={isPltv}
              setIsLiveEdge={setIsLiveEdge}
              cuTvProgram={cuTvProgram}
              myTvChannelProgram={myTvChannelProgram}
              liveui={options.liveui}
              isTimeshiftEnable={true}
            />
            <RestartButton
              player={player}
              setIsLiveEdge={setIsLiveEdge}
              isPltv={pltv.current}
              isLive={
                cuTvProgram?.prevuecode || myTvChannelProgram?.programcode
                  ? false
                  : options.liveui
              }
              isTimeshiftEnable={true}
              restartTv={(val) => setRestartTv(val)}
              isFullscreen={isFullscreen.current}
            />
            <RewindBackButton
              player={player}
              setIsLiveEdge={setIsLiveEdge}
              isPltv={pltv.current}
              isLive={
                cuTvProgram?.prevuecode || myTvChannelProgram?.programcode
                  ? false
                  : options.liveui
              }
              isTimeshiftEnable={true}
              rewindBackTv={(val) => setRewindBackTv(val)}
              isFullscreen={isFullscreen.current}
              program={myProgram}
            />
            {pltv.current || !!cuTvProgram ? (
              <RewindForwardButton
                player={player}
                isFullscreen={isFullscreen.current}
              />
            ) : null}
            <AudioControl player={player} isFullscreen={isFullscreen.current} />

            <div style={{ display: "flex", marginLeft: "auto" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {pltv.current || !!cuTvProgram?.epgProgramId ? (
                  <BackLiveButton
                    setIsLiveEdge={setIsLiveEdge}
                    isProgram={!!cuTvProgram}
                  />
                ) : null}
                {!isFirefox ? (
                  <PictureInPictureButton
                    player={player}
                    isFullscreen={isFullscreen.current}
                    poster={poster}
                    vod={vod}
                    selectedEpisode={selectedEpisode}
                    liveui={options.liveui}
                    isPreview={isPreview}
                    selectedSeason={selectedSeason}
                    pictureInPicture={pictureInPicture}
                    setPictureInPicture={(val) => {
                      setPictureInPicture(val);
                      if (document.pictureInPictureElement && !val) {
                        document.exitPictureInPicture();
                      }
                    }}
                    location={location}
                    setIsPaused={(val) => {
                      setIsPaused(val);
                    }}
                    goBack={(breakPoint) => {
                      sendMediaReport(reportingEvent.E_MEDIA_END);
                      if (window.location.href.includes("livetv")) {
                        history.push("/dashboard");
                        return;
                      }
                      if (!isRecord && !fromVodGrid && !fromTvGuide) {
                        goBackVodDetail(breakPoint ? breakPoint : -1);
                      } else {
                        closeRecord();
                      }
                    }}
                    isRecord={isRecord}
                    sendMediaReport={sendMediaReport}
                    isPltv={isPltv}
                    setIsLiveEdge={setIsLiveEdge}
                    cuTvProgram={cuTvProgram}
                    myTvChannelProgram={myTvChannelProgram}
                    textTracks={isSafari ? textTracks : vodCaptions.current}
                    program={myProgram}
                  />
                ) : null}
                {isFullscreen.current ? (
                  <PlayerInfo
                    player={player}
                    prevueCode={prevueCode}
                    setSelectedCutvProgram={setSelectedCutvProgram}
                    selectedChannel={channel}
                    userFrameConfig={userFrameConfig}
                    userInfo={userInfo}
                    location={location}
                    history={history}
                    setRecordStatus={setRecordStatus}
                    isFullscreen={isFullscreen.current}
                  />
                ) : null}

                <RecordButton
                  recordStatus={recordStatus}
                  setIsClickedRecord={(val) => {
                    setIsClickedRecord(val);
                  }}
                  isClickedRecord={isClickedRecord}
                  selectedChannel={channel}
                  programInfo={programInfo}
                  isFullscreen={isFullscreen.current}
                />
              </div>

              {!isSafari && isFullscreen.current ? (
                <QualityButton
                  player={player}
                  liveui={options.liveui}
                  onChangeQuality={() => {
                    sendMediaReport(reportingEvent.E_MEDIA_SWITCHBIT);
                  }}
                  levels={qualityLevels.current}
                  isFullscreen={isFullscreen.current}
                  src={src}
                />
              ) : null}
              <TrackControl
                player={player}
                textTracks={vodCaptions.current}
                audioTracks={audioItems.current}
                isFullscreen={isFullscreen.current}
                liveui={true}
                smptePlayer={smptePlayer.current}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* {!isFullscreen.current && (pltv.current || !!cuTvProgram) ? (
                  <BackLiveButton
                    setIsLiveEdge={setIsLiveEdge}
                    isProgram={!!cuTvProgram}
                  />
                ) : null*/}
                <FullscreenButton
                  isFullscreen={isFullscreen.current}
                  setFullscreen={(val) => {
                    isFullscreen.current = val;
                    if (options.liveui) {
                      setIsRenderChannellist(val);
                    }
                  }}
                  player={player}
                  src={src}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="control-buttons">
            <PlayButton
              player={player}
              isPaused={isPaused}
              setIsPaused={setIsPaused}
              goBack={goBackVodDetail}
              sendMediaReport={sendMediaReport}
              liveui={options.liveui}
              isTimeshiftEnable={true}
            />
            <RestartButton
              player={player}
              isTimeshiftEnable={true}
              isFullscreen={true}
            />
            <RewindBackButton
              player={player}
              setIsRestartProgram={setIsRestartProgram}
              isPltv={pltv.current}
              isLive={
                cuTvProgram?.prevuecode || myTvChannelProgram?.programcode
                  ? false
                  : options.liveui
              }
              isTimeshiftEnable={true}
              isFullscreen={true}
              program={myProgram}
            />

            <RewindForwardButton player={player} isFullscreen={true} />
            <AudioControl player={player} isFullscreen={true} />
            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                alignItems: "center"
              }}
            >
              <PlaybackRate player={player} isFullscreen={true} />
              {!isFirefox ? (
                <PictureInPictureButton
                  player={player}
                  isFullscreen={true}
                  poster={poster}
                  vod={vod}
                  selectedEpisode={selectedEpisode}
                  liveui={options.liveui}
                  isPreview={isPreview}
                  selectedSeason={selectedSeason}
                  pictureInPicture={pictureInPicture}
                  setPictureInPicture={(val) => {
                    setPictureInPicture(val);
                    if (document.pictureInPictureElement && !val) {
                      document.exitPictureInPicture();
                    }
                  }}
                  location={location}
                  setIsPaused={(val) => {
                    setIsPaused(val);
                  }}
                  goBack={(breakPoint) => {
                    sendMediaReport(reportingEvent.E_MEDIA_END);
                    if (window.location.href.includes("livetv")) {
                      history.push("/dashboard");
                      return;
                    }
                    if (!isRecord && !fromVodGrid && !fromTvGuide) {
                      goBackVodDetail(breakPoint ? breakPoint : -1);
                    } else {
                      closeRecord();
                    }
                  }}
                  isRecord={isRecord}
                  sendMediaReport={sendMediaReport}
                  textTracks={isSafari ? textTracks : vodCaptions.current}
                  program={myProgram}
                />
              ) : null}
              <TrackControl
                player={player}
                textTracks={vodCaptions.current}
                audioTracks={audioItems.current}
                isFullscreen={true}
              />
              {!isSafari ? (
                <QualityButton
                  player={player}
                  onChangeQuality={() => {
                    sendMediaReport(reportingEvent.E_MEDIA_SWITCHBIT);
                  }}
                  levels={qualityLevels.current}
                  isFullscreen={true}
                  src={src}
                />
              ) : null}
              <FullscreenButton
                isFullscreenVod={true}
                setFullscreen={(val) => {
                  isFullscreen.current = val;
                  if (options.liveui) {
                    setIsRenderChannellist(val);
                  }
                }}
                player={player}
                src={src}
              />
            </div>
          </div>
        )}
      </div>,
      document.getElementById(`vod-player-row-${options.liveui}`)
    );
  };

  const sendMediaReport = (eventid) => {
    if (userInfo?.usertype === "1") {
      let eventTime = moment().utc().format("yyyyMMDDHHmmss");
      let reportObject = {};
      reportObject.eventtype = reportingEvent.E_MEDIA;
      reportObject.eventid = eventid;
      reportObject.eventtime = eventTime;
      reportObject.result = "0";
      reportObject.playid = parseInt(Math.random() * 10000000000);
      reportObject.programcode = "0";
      reportObject.channelcode = "0";
      reportObject.producttype = "0";
      reportObject.productcode = "0";

      let definition = getVideoProfile();

      switch (definition) {
        case "480":
          reportObject.definition = "2";
          break;
        case "720":
          reportObject.definition = "4";
          break;
        default:
          reportObject.definition = "0";
          break;
      }

      if (!channel?.channelcode) {
        if (vod?.programcode) {
          reportObject.programcode = vod.programcode;
          reportObject.contenttype = vod.programtype;
          reportObject.contentcode = vod.contentcode;
          reportObject.productcode = vod.productcode;
          reportObject.producttype = vod.producttype;
        } else if (vod?.prevuecode) {
          reportObject.contentcode = vod.prevuecode;
          if (isRecord) {
            reportObject.contenttype = "3";
          } else {
            reportObject.contenttype = "4";
          }
        }
      } else {
        reportObject.contenttype = "2";
        reportObject.contentcode = channel.channelcode;
        reportObject.channelcode = channel.channelcode;
      }

      collectAgent.current.logMedia(reportObject);
    }
  };

  const renderDurations = () => {
    ReactDOM.render(
      <Durations currentTime={currentTime} duration={duration} />,
      document.getElementById(`durations-${options.liveui}`)
    );
  };

  const renderPlayerChannelList = (player, channel) => {
    ReactDOM.render(
      <PlayerChannelList
        selectedChannel={channel}
        onSelectChannel={channelSelection}
        isFullscreen={isRenderChannellist}
        videoRef={player.el()}
        channels={channels}
        genres={genres}
        selectedGenre={selectedGenre}
        setSelectedGenre={setSelectedGenre}
        setSelectedCutvProgram={() => {
          /* setSelectedCutvProgram(null); */
        }}
        searchFilter={searchFilter}
        userFrameConfig={userFrameConfig}
        adminProfile={adminProfile}
        profile={profile}
        setCanWatch={setCanWatch}
        setLockChannelCode={setLockChannelCode}
      />,
      document.getElementById(`player-channel-list-${options.liveui}`)
    );
  };

  const renderProgressBar = (player) => {
    ReactDOM.render(
      <PlayerBar
        channel={channel}
        program={myProgram}
        player={player}
        isRestartProgram={isRestartProgram}
        setIsRestartProgram={setIsRestartProgram}
        setIsLiveEdge={setIsLiveEdge}
        src={src}
        isTimeshiftEnable={true}
        isPaused={isPaused}
        isPltv={isPltv}
        onSeek={() => {
          sendMediaReport(reportingEvent.E_MEDIA_SEEK);
        }}
        restartTv={restartTv}
        rewindBackTv={rewindBackTv}
      />,
      document.getElementById(`player-bar-${options.liveui}`)
    );
  };
  const renderProductsInContent = (player) => {
    console.log("render pause", isPaused);
    ReactDOM.render(
      <ProductsInContent
        player={player}
        isPaused={isPaused}
        isFullscreen={isFullscreen.current}
        liveui={options?.liveui}
      />,
      document.getElementById(
        `${options.liveui ? "product-list-left" : "product-list-right"}`
      )
    );
  };

  const renderSmpteSubtitleBox = () => {
    ReactDOM.render(
      <div id="safari-smpte-caption"></div>,
      document.getElementById(`safari-smpte-${options.liveui}`)
    );
  };

  const renderNextEpisode = (player) => {
    ReactDOM.render(
      <NextEpisode
        nextEpisode={nextEpisode}
        vod={vod}
        selectedSeason={selectedSeason}
        playNextEpisode={() => {
          continueClicked.current = true;
          unMountNextEpisodeComponent();
          showControlBarElements();
          playNextEpisode();
        }}
        hideNextEpisode={() => {
          continueClicked.current = true;
          unMountNextEpisodeComponent();
          showControlBarElements();
        }}
        player={player}
        isCutvVod={isCutvVod}
      />,
      document.getElementById(`player-next-episode`)
    );
  };

  const unMountNextEpisodeComponent = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById(`player-next-episode`)
    );
  };

  const renderExitButton = (player) => {
    ReactDOM.render(
      <ExitButton
        contentId={isRecord ? epgProgramId : vod?.id}
        player={player}
        vod={vod}
        goBack={() => {
          sendMediaReport(reportingEvent.E_MEDIA_END);
          if (!isRecord && !fromVodGrid && !fromTvGuide) {
            goBackVodDetail();
          } else {
            closeRecord();
          }
        }}
        showExitNotification={(val) => setShowExitNotification(val)}
      />,
      document.getElementById(`player-exit-button-${options.liveui}`)
    );
  };

  const renderClickableScreen = () => {
    let onClickTimer;

    ReactDOM.render(
      <div
        className="clickable-screen"
        onClick={(e) => {
          onClickTimer && clearTimeout(onClickTimer);
          onClickTimer = setTimeout(() => {
            if (true) {
              if (player.paused()) {
                sendMediaReport(reportingEvent.E_MEDIA_PLAY);
                setIsPaused(false);

                player.play();
              } else {
                sendMediaReport(reportingEvent.E_MEDIA_PAUSE);
                setIsPaused(true);

                player.pause();
                if (
                  options.liveui &&
                  !isPltv &&
                  !cuTvProgram?.prevuecode &&
                  !myTvChannelProgram?.programcode
                ) {
                  setIsLiveEdge("1");
                  setPltvPaused(true);
                }
              }
            }
          }, 300);
        }}
        onDoubleClick={() => {
          onClickTimer && clearTimeout(onClickTimer);
          let val = !isFullscreen.current;
          val ? player.requestFullscreen() : player.exitFullscreen();
          isFullscreen.current = val;
          if (options.liveui) {
            setIsRenderChannellist(val);
          }
        }}
        style={{
          width: `100%`,
          height: `100%`
          //backgroundColor: `red`
        }}
      ></div>,
      document.getElementById("clickable-screen-container")
    );
  };

  const renderPauseAdBanner = (showAd) => {
    ReactDOM.render(
      <div
        className={
          showBannerAd.current &&
          showAd &&
          !showExitNotification &&
          !showBookmarkPopup
            ? "advertisement-container show"
            : "advertisement-container hide"
        }
      >
        <BannerAd
          key="video-pause-banner-ad"
          bannerId="video-banner-ad"
          configShowAd={userFrameConfig?.showGoogleAD === "1"}
          userInfo={userInfo}
          bannerConfig={pauseBannerAdConfig}
          setShowBannerAd={(val) => {
            showBannerAd.current = val;
            if (!val) {
              onVideoPaused(true);
            } else {
              //renderPauseAdBanner(showAd);
            }
          }}
          setAdSlot={(val) => {
            if (val) {
              adSlot.current = val;
            }
          }}
          player={playerRef.current}
        />
      </div>,
      document.getElementById(`pause-ad-${options.liveui}`)
    );
  };

  const onVideoPaused = (condition = false) => {
    let el = document.getElementById(`pause-ad-${options.liveui}`);
    if (el)
      if (condition) {
        el.style.display = "none";
      } else {
        if (
          isPaused &&
          !playerRef.current.ended() &&
          showAd &&
          !playerRef.current.seeking()
        ) {
          el.style.display = "flex";
          //renderPauseAdBanner(showAd);
        } else {
          el.style.display = "none";
          // ReactDOM.unmountComponentAtNode(
          //   document.getElementById(`pause-ad-${options.liveui}`)
          // );
        }
      }
  };

  const selectedEpisodeRef = useRef(null);

  useEffect(() => {
    selectedEpisodeRef.current = selectedEpisode;
  }, [selectedEpisode]);

  useEffect(() => {
    cuTvRef.current = cuTvProgram;
  }, [cuTvProgram]);

  const goBackVodDetail = (brPoint = 0) => {
    let bookmarkType;
    let bookmarkTime;
    let currentTime;
    let duration;
    if (brPoint) {
      bookmarkTime = parseInt(brPoint);
    } else {
      currentTime = parseInt(player?.currentTime());
      bookmarkTime = currentTime;
    }
    if (vod?.programtype == 1) {
      bookmarkType = 1;
    } else if (vod?.programtype == 14) {
      if (selectedEpisode) {
        bookmarkType = 4;
        bookmarkTime = `${selectedSeason.seriesseason}|${selectedEpisodeRef.current?.seriesnum}|${currentTime}|${selectedEpisodeRef.current?.contentcode}`;
      }
    }
    if (!!vod?.channelcode) {
      bookmarkType = 19;
    }
    duration = playerRef.current.duration();
    if (vod?.type === "SERIES") {
      if (currentTime <= duration - 1 && duration && currentTime) {
        const bookmarkControl = async () => {
          const { event } = await getVodPlayUrlById(
            selectedEpisode?.id,
            currentTime
          );
        };
        bookmarkControl();
      }
    }
    if (!isPreview && brPoint != -1) {
      if (bookmarkTime <= duration - 60 || !duration || !bookmarkTime) {
        addBookmarkToContent(
          bookmarkTime,
          vod?.contentcode,
          vod?.columncode,
          bookmarkType,
          vod?.programcode
        );
      } else {
        deleteBookmark();
        videoCompleted();
      }
    }
    closePlayer(true);
  };

  const videoCompleted = async () => {
    let programCode;
    if (vod?.programtype === "1") {
      programCode = vod?.programcode;
    } else if (vod?.programtype === "14") {
      programCode = `${selectedSeason.seriesseason},${selectedSeason.seriesprogramcode},${selectedEpisodeRef.current?.contentcode}`;
    }

    const { data } = await sendVideoComplated(
      vod?.contentcode,
      vod?.columncode,
      programCode
    );
  };

  const deleteBookmark = async () => {
    let bookmarkType;
    let contentCode;
    if (vod?.programcode) {
      if (vod?.programtype == 1) {
        bookmarkType = 1;
      } else if (vod?.programtype == 14) {
        if (selectedEpisode) {
          bookmarkType = 4;
        }
      }
      if (!!vod?.channelcode) {
        bookmarkType = 19; // cutv series
      }
      contentCode = vod.contentcode;
    }
    if (cuTvProgram?.prevuecode) {
      bookmarkType = 3;
      contentCode = cuTvProgram.prevuecode;
    }
    if (vod?.prevucecode) {
      bookmarkType = 3;
      contentCode = vod.prevuecode;
    }

    const { data } = await deleteBookmarkContent(
      bookmarkType,
      vod?.contentcode
    );
  };

  const closeRecord = () => {
    addBookmarkToContent(
      parseInt(player?.currentTime()),
      vod?.prevuecode,
      vod?.columncode,
      3
    );
    closeRecordModal();
  };

  const addBookmarkToContent = async (
    breakPoint,
    contentCode,
    columnCode,
    bookmarkType,
    programCode
  ) => {
    if (vod?.programcode && !nextEpisode) {
      setBookmarkTime(breakPoint);
    }
    /*   const { data } = await addBookmark(
      bookmarkType,
      contentCode,
      columnCode,
      breakPoint,
      playerRef.current.duration()
    ); */

    /*  sendCustomBookmarkReport(
      contentCode,
      programCode,
      breakPoint,
      columnCode,
      bookmarkType,
      1,
      data.returncode,
      data.errormsg
    ); */
  };

  const getBookmark = async (bookmarkType, contentCode, setBookmark) => {
    const { data } = await getBookmarkOfContent(bookmarkType, contentCode);
    if (data.returncode === "0") {
      let item = data.data[0];
      if (item?.breakpoint != 0 && !!item?.breakpoint) {
        // player.currentTime(parseInt(item?.breakpoint));
        setBookmark(parseInt(item?.breakpoint));
      }
    } else {
      setBookmark(null);
    }
  };

  const createElements = (player) => {
    let playerEl = player.el();
    let controlBar = player.getChild("controlBar");
    let progressBar = controlBar.getChild("progressControl");

    let el = document.createElement("div");
    el.classList.add("player-row");
    el.setAttribute("id", `vod-player-row-${options.liveui}`);

    let playButtonEl = document.createElement("div");
    playButtonEl.classList.add("play-button-box");
    playButtonEl.setAttribute("id", `play-button-${options.liveui}`);

    let gradientEl = document.createElement("div");
    gradientEl.classList.add("player-gradient");
    gradientEl.setAttribute("id", `player-gradient`);

    let exitButtonel = document.createElement("div");
    exitButtonel.classList.add("exit-button-container");
    exitButtonel.setAttribute("id", `player-exit-button-${options.liveui}`);

    let clickableScreenel = document.createElement("div");
    clickableScreenel.classList.add("clickable-screen-container");
    clickableScreenel.setAttribute("id", "clickable-screen-container");

    let productsInContentListEl = document.createElement("div");
    productsInContentListEl.classList.add("products-in-content-container");
    productsInContentListEl.setAttribute(
      "id",
      `${options.liveui ? "product-list-left" : "product-list-right"}`
    );
    playerEl.appendChild(productsInContentListEl);

    let shareButtonEl = document.createElement("div");
    shareButtonEl.classList.add("share-button-container");
    shareButtonEl.setAttribute("id", `player-share-button-${options.liveui}`);

    let durationsEl = document.createElement("div");
    durationsEl.classList.add("durations-box");
    durationsEl.setAttribute("id", `durations-${options.liveui}`);

    if (options.liveui) {
      let channelListEl = document.createElement("div");
      channelListEl.classList.add("channel-list-container");
      channelListEl.setAttribute("id", `player-channel-list-${options.liveui}`);
      playerEl.appendChild(channelListEl);

      let prevProgramsEl = document.createElement("div");
      prevProgramsEl.classList.add("prev-program-list-container");
      prevProgramsEl.setAttribute(
        "id",
        `player-prev-program-list-${options.liveui}`
      );
      playerEl.appendChild(prevProgramsEl);

      let nextProgramsEl = document.createElement("div");
      nextProgramsEl.classList.add("next-program-list-container");
      nextProgramsEl.setAttribute(
        "id",
        `player-next-program-list-${options.liveui}`
      );
      playerEl.appendChild(nextProgramsEl);

      let myTvChannelPrevProgramsEl = document.createElement("div");
      myTvChannelPrevProgramsEl.classList.add("prev-program-list-container");
      myTvChannelPrevProgramsEl.setAttribute(
        "id",
        `player-mytvchannel-prev-program-list-${options.liveui}`
      );
      playerEl.appendChild(myTvChannelPrevProgramsEl);

      let myTvChannelNextProgramsEl = document.createElement("div");
      myTvChannelNextProgramsEl.classList.add("next-program-list-container");
      myTvChannelNextProgramsEl.setAttribute(
        "id",
        `player-mytvchannel-next-program-list-${options.liveui}`
      );
      playerEl.appendChild(myTvChannelNextProgramsEl);

      let playerBarEl = document.createElement("div");
      playerBarEl.classList.add("live-player-bar");
      playerBarEl.setAttribute("id", `player-bar-${options.liveui}`);
      controlBar.el().appendChild(playerBarEl);

      if (isSafari) {
        let smpteSubtitleEl = document.createElement("div");
        smpteSubtitleEl.classList.add("safari-smpte-box");
        smpteSubtitleEl.setAttribute("id", `safari-smpte-${options.liveui}`);
        playerEl.appendChild(smpteSubtitleEl);
      }
    }
    if (!!nextEpisode) {
      let nextEpisodeEl = document.createElement("div");
      nextEpisodeEl.classList.add("next-episode-container");
      nextEpisodeEl.setAttribute("id", `player-next-episode`);
      playerEl.appendChild(nextEpisodeEl);
    }

    controlBar.el().appendChild(el);
    controlBar.el().insertBefore(playButtonEl, controlBar.el().childNodes[0]);
    progressBar.el().appendChild(durationsEl);

    // playerEl.appendChild(centeredPlayButtonEl);
    playerEl.appendChild(exitButtonel);
    playerEl.appendChild(clickableScreenel);
    playerEl.appendChild(shareButtonEl);
    playerEl.appendChild(gradientEl);
    setHasRowEl(true);
  };

  useEffect(() => {
    if (src !== -1 && src !== -2) {
      if (!player) {
        createPlayer();
      }
    } else if (!!player) {
      destroyPlayer();
    }
  }, [player, channel, src]);

  useEffect(() => {
    if (player) {
      renderPlayer(player);
      player.el().addEventListener("error", (event) => {});
    }
  }, [player]);

  useEffect(() => {
    if (player && src) {
      if (src !== -1 && !error) {
        if (options.liveui) {
          renderClickableScreen();
          callRenderProgressBar(player);
        }
        renderControlBar(player);
        renderProductsInContent(player);
      } else {
        setIsRenderChannellist(false);
      }
    }
  }, [
    isRestartProgram,
    player,
    src,
    restartTv,
    rewindBackTv,
    isPaused,
    channel,
    isPltv,
    myProgram,
    textTracks
  ]);

  useEffect(() => {
    if (playerMap["true"] && playerMap["false"]) {
      playerMap["true"].pause();
    }
    if (src) {
      if (src !== -1 && !error) {
        !!player && initPlayer();
      }
      if (error?.code === 401) {
        setShowNotification(true);
      } else {
        setShowNotification(false);
      }
    }
  }, [src, error]);

  useEffect(() => {
    if (src === -1 && !!player) {
      setPlayer(null);
      playerRef.current = null;
    }
    // setTextTracks([]);
    //setAudioTracks([]);
    if (cuTvProgram?.prevuecode || myTvChannelProgram?.programcode) {
      setPltv(false);
    }
  }, [src]);

  useEffect(() => {
    if (!!player && thumbnails?.length) {
      if (cuTvProgram?.prevuecode || vod?.prevuecode || vod?.programcode)
        initThumbnail(player, thumbnails);
    }
  }, [player, thumbnails, channel]);

  useEffect(() => {
    if (channel && player) {
      sendMediaReport(reportingEvent.E_MEDIA_SWITCH);

      renderPlayerChannelList(player, channel);
      renderControlBar(player);
      renderProductsInContent(player);
    }
  }, [channel, player, isRenderChannellist, channelItems]);

  useEffect(() => {
    if (recordStatus && player) {
      renderControlBar(player);
    }
  }, [recordStatus, player, prevueCode]);

  useEffect(
    () => () => {
      if (!!bookmarkIntId.current) clearInterval(bookmarkIntId.current);
      if (!!intervalId.current) clearInterval(intervalId.current);
      /* setSelectedCutvProgram(null); */
      document.removeEventListener("keydown", handleKeyPress);
      document.removeEventListener("keydown", getHandleKeyRef());
      if (!!playerMap[options.liveui]) {
        sendMediaReport(reportingEvent.E_MEDIA_END);
        destroyPlayer();
        //googletag.destroySlots([adSlot.current]);
      }
      destroyFingerPrint();
      //destroyMesFingerPrint();
      // hideMesFingerPrint();
      // hideFingerPrint();
    },
    []
  );

  useEffect(() => {
    if (userInfo?.usertype === "1") {
      collectAgent.current = getColectAgent();
    }
  }, [userInfo]);

  useEffect(() => {
    onVideoPaused();
  }, [isPaused]);

  //when finger print command came from MES
  useEffect(() => {
    if (mesFingerPrintParam) {
      if (mesFingerPrintParam?.channelcode.includes(channel?.channelcode)) {
        //show finger print immediately
        let obj = fillFingerPrintParams(mesFingerPrintParam);
        obj.player = playerRef.current;
        obj.userName = userInfo.usercode;
        obj.fromMes = true;
        initFingerPrint(obj);
      }
    }
  }, [mesFingerPrintParam]);

  useEffect(() => {
    if (currentTime) {
      if (options.liveui) {
        if (isFullscreen.current) {
          renderDurations();
        } else if (document.getElementById(`durations-${options.liveui}`)) {
          ReactDOM.unmountComponentAtNode(
            document.getElementById(`durations-${options.liveui}`)
          );
        }
      } else {
        renderDurations();
      }
    }
  }, [currentTime, isFullscreen.current]);

  useEffect(() => {
    // this useEffect tells what to do when the space button is clicked.
    function isClickedSpace(event) {
      if (player) {
        if (
          document.activeElement.tagName != "INPUT" &&
          event.code == "Space" &&
          (isTimeshiftEnable ||
            cuTvProgram?.prevuecode ||
            myTvChannelProgram?.programcode)
        ) {
          event.preventDefault();
          if (player.paused()) {
            sendMediaReport(reportingEvent.E_MEDIA_PLAY);
            setIsPaused(false);

            player.play();
          } else {
            sendMediaReport(reportingEvent.E_MEDIA_PAUSE);
            setIsPaused(true);

            player.pause();
            if (
              options.liveui &&
              !pltv.current &&
              !cuTvRef.current?.prevuecode &&
              !myTvChannelProgram?.programcode
            ) {
              setIsLiveEdge("1");
              setPltvPaused(true);
            }
          }
        }
      }
    }

    document.addEventListener("keydown", isClickedSpace);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("keydown", isClickedSpace);
    };
  }, [player]);

  /*  console.log({ src, error }); */

  return (
    <div
      id={`player-container-${options.liveui}`}
      className="video-player"
      style={!options.liveui ? { width: "100%", height: "100%" } : null}
      ref={playerContainerRef}
    >
      {src === -1 ? (
        error?.code === 303 ? (
          <div>
            {/* <img
              style={{ width: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/locked-channel.jpg`}
            /> */}
            <ChannelLocking />
          </div>
        ) : error?.code === 302 ? (
          <div>
            {/* <img
              style={{ width: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/product.jpg`}
            /> */}
            <ChannelCoverage />
          </div>
        ) : error?.code === 101 ? (
          <div>
            {/* <img
              style={{ width: "100%" }}
              src={`${process.env.PUBLIC_URL}/images/parental-control.jpg`}
            /> */}
            <ChannelControl />
          </div>
        ) : showNotification ? (
          <Notification
            text={localizationStrings.loginText}
            confirmText={localizationStrings.login.text}
            notConfirmText={localizationStrings.cancel}
            onClickConfirm={() =>
              window.location.replace(
                `${localStorage.getItem("loginurl")}?lan=${localStorage.getItem(
                  "language"
                )}&deviceid=${localStorage.getItem("deviceid")}`
              )
            }
            onClickNotConfirm={() => {
              setShowNotification(false);
              history.push(`/dashboard/livetv/first`);
              setSelectedChannel(channelItems[0]);
            }}
          />
        ) : null
      ) : null}
      {showNotificationError ? (
        <Notification
          text={`${localizationStrings.errorCode}: ${playerError.code} ${localizationStrings.errorMessage}: ${playerError.message}`}
          confirmText={localizationStrings.login.confirm}
          onClickConfirm={() => {
            setShowNotificationError(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default VideoPlayer;
