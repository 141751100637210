import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VodItem from "../vodItem";
import carouselImg from "../../assets/icons/svg/Icon awesome-angle-right.svg";
import { useHistory, useParams } from "react-router-dom";
import { getVodsByLabel } from "../../api/vod/vod";

import AngleRightIcon from "../../assets/icons/svg/AngleRightIcon";
import localizationStrings from "../../statics/localization";

const Carousel = ({ column, slidesToShow, vod, label, margin, dontUseGrid, padding, onHoverItem }) => {
  const [vods, setVods] = useState([]);
  const [isVertical, setIsVertical] = useState(false);

  const slider = useRef(null);
  const pageNo = useRef(0);
  const vodRef = useRef([]);
  const totalCountRef = useRef(0);

  const history = useHistory();

  let pageItemCount = 16; // request for other pages vod count

  slidesToShow = slidesToShow ? slidesToShow : isVertical ? 7 : 5;

  let slidesToScroll = isVertical ? 7 : 5;

  const getVodList = async () => {
    const { data } = await getVodsByLabel(
      label.id,
      pageNo.current,
      pageItemCount
    );

    pageNo.current++;

    if (data.contents) {
      vodRef.current = vodRef.current.concat(data?.contents);
      totalCountRef.current = data?.totalContents;
      setVods(vodRef.current);

      setIsVertical(data?.vertical);
    }
  };

  const next = () => {
    slider.current.slickNext();

    if (totalCountRef.current > vods?.length) {
      setTimeout(() => {
        getVodList();
      }, 600);
    }
  };

  useEffect(() => {
    if (label) {
      getVodList();
    }
  }, [label]);
  
  useEffect(() => {
    if(!!vod?.relatedContents){
      slidesToShow = 4;
    }
  }, []);

  useEffect(() => {
    if (!!(vod?.relatedContents)) {
      setVods(vod?.relatedContents);
      setIsVertical(true);
      slidesToShow = 4;
    }
  }, [vod?.relatedContents]);

  let isKeepWatching = label?.name === localizationStrings.continueToWatch;

  const items = vods?.map((vod, index) => {
    return (
      <VodItem
        key={vod.id}
        item={vod}
        showProgress={isKeepWatching}
        isFavList={false}
        useContentType={false}
        isVertical={isVertical}
        label={label}
        index={index}
        onHoverItem={onHoverItem}
        slidesToScroll={slidesToScroll}
      />
    );
  });

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    arrows: false,
    draggable: false,
    lazyLoad: false
  };

  return (
    <div
      className="content-carousel"
      style={{
        display: !vods.length ? "none" : "",
        padding: padding
      }}
      key={label?.id}
    >
      <label
        className="label-title"
        style={{
          marginLeft: margin
        }}
        onClick={() => {
          if (!dontUseGrid) {
            history.push(`/dashboard/genre/${label?.id}/${label?.name}`);
          }
        }}
        //label.columncode,label.columntype
      >
        {!!vod?.relatedContents ? (
          <span>{column?.columnname}</span>
        ) : (
          <span>{label?.name}</span>
        )}
        {!dontUseGrid ? (
          <img className="see-all-icon" src={carouselImg} />
        ) : null}
        {/* fav list
          <div
            ref={optionRef}
            className="dot-options"
            onClick={(e) => {
              e.stopPropagation();
              setShowOptions(!showOptions);
            }}
          >
            <div className="dots-wrapper" style={{ right: "0px" }}>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>

              <div className={`options-box ${showOptions ? "show" : ""}`}>
                <div className="tri-angle"></div>
                <div className="options">
                  <div
                    className="option"
                    onClick={() => {
                      vods.forEach((item) => {
                        removeContentFromFavorite(item, true);
                      });
                    }}
                  >
                    <TrashIcon />
                    <span>{localizationStrings.removeList}</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
      </label>

      <div className="slider-row">
        <div className="slick-wrapper">
          <Slider ref={slider} {...settings}>
            {items}
          </Slider>
        </div>
        <div
          className="direction-button prev"
          style={
            vods.length > slidesToShow
              ? {}
              : {
                  visibility: "hidden"
                }
          }
          onClick={() => slider.current.slickPrev()}
        >
          <span>
            <AngleRightIcon />
          </span>
        </div>
        <div
          className="direction-button next"
          style={
            vods.length > slidesToShow
              ? {}
              : {
                  visibility: "hidden"
                }
          }
          onClick={() => next()}
        >
          <AngleRightIcon />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
