import React, { useContext, useEffect, useRef, useState } from "react";
import RtukAgeIcon from "../../assets/icons/svg/RtukAgeIcon";
import localizationStrings from "../../statics/localization/Localization";
import {
  getVodDetail,
  getVodPlayUrl,
  getSerieDetail,
  getSeasons,
  getBundleVods,
  getPreviewUrl,
  getEpisodes,
  getVodById,
  getVodPlayUrlById,
  getTrailerPlayUrlById
} from "../../api/vod/vod";
import SinglePlayIcon from "../../assets/icons/svg/SinglePlayIcon";
import Modal from "../modal/Modal";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { SessionContext } from "../../context/SessionContext";
import VideoPlayer from "../videoPlayer/VideoPlayer";
import useQuery from "../../utils/useQuery";
import { getAllThumbnail } from "../../api/common/commonApi";
import RtukGeneralIcon from "../../assets/icons/svg/RtukGeneralIcon";
import RtukSevenAgeIcon from "../../assets/icons/svg/RtukSevenAgeIcon";
import RtukThirteenAgeIcon from "../../assets/icons/svg/RtukThirteenAgeIcon";
import Notification from "../notification";
import { doAuth, doUnsubscribe } from "../../api/user/user";
import defaultDetailPoster from "../../assets/images/default-vod-horizontal-poster.png";
import { formatThumbnails } from "../../utils";
import TrailerPlayer from "../trailerPlayer";
import PopupCloseIcon from "../../assets/icons/svg/PopupCloseIcon";
import BackIcon from "../../assets/icons/svg/BackIcon";
import FavoriteAction from "../favoriteAction";
import LikeAction from "../likeAction";
import { fancyTimeFormat } from "../../utils/fancyTimeFormat";
import defaultPosterVertical from "../../assets/images/no-portrait-poster.png";
import CastCarousel from "../castCarousel/CastCarousel";
import Carousel from "../carousel/Carousel";
import AudioOnIcon from "../../assets/icons/svg/AudioOnlIcon";
import AudioOffIcon from "../../assets/icons/svg/AudioOffIcon";
import MiniVodDetail from "../miniVodDetail/MiniVodDetail";
import EpisodesIcon from "../../assets/icons/svg/EpisodesIcon";
import EpisodeItem from "../episodeItem";
import useOutsideClick from "../../utils/useOutsideClick";
import Payment from "../payment/Payment";
import BundleIcon from "../../assets/icons/svg/BundleIcon";
import { getIsApiErrorPopupOpen, setWhereFrom } from "../../statics/config";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import PackageIcon from "../../assets/icons/svg/PackageIcon";
import ChannelControl from "../videoAlert/ChannelControl";
import formatProgramThumbnails from "../../utils/formatProgramThumbnails";
import { getParentalAdvisoryElements } from "../../utils/getParentalAdvisory";
import mainLoadingGif from "../../assets/loading.gif";
import PlayerPlayIcon from "../../assets/icons/svg/PlayerPlayIcon";
import PauseIcon from "../../assets/icons/svg/PauseIcon";
import { isSafari } from "react-device-detect";
import {
  getTrailerAudioOnGlobal,
  setTrailerAudioOnGlobal
} from "../../statics/config";
import { prettyTimeFormatter } from "../../utils/prettyTimeFormatter";
import BigLockIcon from "../../assets/icons/svg/BigLockIcon";

const playerOptions = {
  // fill: true,
  fluid: true,
  responsive: true,
  preload: "auto",
  controls: true,
  liveui: false,
  autoplay: true,
  playbackRates: [0.5, 1, 1.5, 2],
  disablePictureInPicture: false,
  controlBar: {
    pictureInPictureToggle: true
  },
  html5: {
    nativeTextTracks: true,
    dash: {
      useTTML: true
    }
  }
};

const VodDetail = () => {
  const [vod, setVod] = useState(null);
  const [seasons, setSeasons] = useState([]);
  const [playUrl, setPlayUrl] = useState("");
  const [isFav, setIsFav] = useState(false);
  const [thumbnails, setThumbnails] = useState([]);
  const [showWatchNow, setShowWatchNow] = useState(false);
  const [posterUrl, setPosterUrl] = useState("");
  const [verticalPosterPath, setVerticalPosterPath] = useState("");
  const [bookmarkTime, setBookmarkTime] = useState(null);
  const [showProductImage, setShowProductImage] = useState(false);
  const [showAd, setShowAd] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);
  const [serieProducts, setSerieProducts] = useState([]);
  const [nextEpisode, setNextEpisode] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [selectedEpisode_, setSelectedEpisode_] = useState(null);
  const [trailerPlayUrl, setTrailerPlayUrl] = useState(null);
  const [serieBookmark, setSerieBookmark] = useState(null);
  const [showCancelPaymentError, setShowCancelPaymentError] = useState(false);
  const [canWatch, setCanWatch] = useState(true); //about parental settings
  const [showBundleMessage, setShowBundleMessage] = useState(false); //about bundle message
  const [bundleData, setBundleData] = useState([]); //about bundle data
  const [_seasonCode, setSeasonCode] = useState(null); //about bundle data
  const [isAudioOn, setIsAudioOn] = useState(getTrailerAudioOnGlobal()); //trailer player sound
  const [isPlay, setIsPlay] = useState(true); //trailer player video playing status
  const [showEpisodes, setShowEpisodes] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [seasonEpisodes, setSeasonEpisodes] = useState([]);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [notificationText, setNotificationText] = useState(null);
  let { vodId } = useParams();
  const {
    path,
    userFrameConfig,
    userInfo,
    setShowLoginMessage,
    showLoginMessage,
    hasLoggedIn,
    profile
  } = useContext(SessionContext);
  const history = useHistory();
  let programType = useQuery().get("ptype");
  let contentCode = useQuery().get("contentcode");
  let episodeCode = useQuery().get("episodecode");
  let fromSearch = useQuery().get("fromsearch");
  let seasonCode = useQuery().get("seasoncode");
  let isDirectlyPlay = useQuery().get("play") === "1";
  let isPipPlay = useQuery().get("pipPlay") === "1";
  const episodes = useRef([]);
  const selectedEpisode = useRef(null);
  const bookmarkedEpisode = useRef(null);
  const bookmarkedSeason = useRef(null);
  const notInProduct = useRef(false);
  const isRent = useRef(false);
  const previewData = useRef({});
  const posterWrapperRef = useRef(null);
  const modalRef = useRef(null);
  const pathRef = useRef(null);
  const playerOpen = useRef(null);
  const showPaymentRef = useRef(false);
  const showWatchNowRef = useRef(false);
  const showCancelPopupRef = useRef(false);
  const trailerUrlRef = useRef(null);
  const showBundleMessageRef = useRef(false);
  const showNotInProductRef = useRef(false);
  const canPurchaseRef = useRef(false);
  const showLoginMessageRef = useRef(false);
  const vodDetailBoxRef = useRef(null);
  const [canPurchase, setCanPurchase] = useState(false);
  const [showMiniVodDetail, setShowMiniVodDetail] = useState(false);
  const [showPreviewButton, setShowPreviewButton] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showNotInProduct, setShowNotInProduct] = useState(false);
  const [currency, setCurrency] = useState(false);

  const currencyMap = {
    eng: "EUR",
    tr: "EUR",
    arb: "EUR"
  };

  useEffect(() => {
    var temp = localStorage.getItem("language");
    const curr = currencyMap[temp] || "USD";
    setCurrency(curr);
  }, []);

  useEffect(() => {
    if (vod) {
      if (vod.type === "SERIES") {
        if (seasonEpisodes.length > 0) {
          isDirectlyPlay && getPlayUrl();
        }
      } else {
        isDirectlyPlay && getPlayUrl();
      }
    }
  }, [isDirectlyPlay, vod, seasonEpisodes]);

  useEffect(() => {
    getEpisodes(selectedSeason?.id);
  }, [selectedSeason]);

  const getEpisodes = async (seasonId) => {
    if (seasonId) {
      const { data } = await getVodById(seasonId);

      setSeasonEpisodes(data?.children);
    }
  };

  useOutsideClick(modalRef, () => {
    if (
      !playerOpen.current &&
      !showPaymentRef.current &&
      !showWatchNowRef.current &&
      !showBundleMessageRef.current &&
      !showCancelPopupRef.current &&
      !showNotInProductRef.current &&
      !showLoginMessageRef.current &&
      !canPurchaseRef.current &&
      !getIsApiErrorPopupOpen()
    ) {
      history.goBack();
      // pathRef.current
      //   ? history.push(pathRef.current)
      //   : document.referrer
      //   ? history.goBack()
      //   : history.push("/dashboard");
    }
  });

  const getVod = async (fromWatchLater = false) => {
    const data = await getVodById(vodId);

    const vodData = data.data;

    getTrailerUrl(vodData?.id);

    /* setShowAd(parseInt(vodData.advertisecontent) & 4);
    setIsFav(vodData.isfavourite === "1"); */

    let verticalPoster = vodData?.posters.find((poster) => poster?.vertical);
    let horizontalPoster = vodData?.posters.find((poster) => !poster?.vertical);

    setVerticalPosterPath(verticalPoster?.url);
    setPosterUrl(horizontalPoster?.url);

    /*  setBookmarkTime(vodData?.breakpoint); */

    setVod(vodData);
    if (vodData?.children) {
      setSeasons(vodData?.children);
      setSelectedSeason(vodData?.children[0]);
    }

    if (!fromWatchLater)
      if (
        !(isDirectlyPlay || isPipPlay) &&
        vodData?.hastrailer === "1" &&
        !fromWatchLater
      ) {
      } else {
        setTrailerPlayUrl(null);
      }

    if (vodData.auth_returncode !== "0") {
      setShowPreviewButton(true);
      if (isDirectlyPlay || isPipPlay) {
        /* getVodPreviewUrl(vodData.programcode, vodData.columncode); */
      }
    } else {
      setShowPreviewButton(false);
      if (isDirectlyPlay || isPipPlay) {
        //setTrailerPlayUrl(null);
        getPlayUrl(
          vodData?.programcode,
          vodData?.columncode,
          vodData?.physicalcontentid
        );
      }
    }
  };

  const getTrailerUrl = async (vodId) => {
    try {
      const { data } = await getTrailerPlayUrlById(vodId);

      setTimeout(() => {
        setTrailerPlayUrl(data);
      }, 500);
    } catch (err) {
      setTrailerPlayUrl(null);
    }
  };

  const getSerie = async (programCode, isEpisode = false) => {
    const data = await getSerieDetail(programCode, contentCode);
    if (data.data.returncode === "0") {
      let serieData = data.data.data[0];
      setShowAd(parseInt(serieData.advertisecontent) & 4);

      if (isEpisode) {
        getSerie(serieData.seriesprogramcode);
        setSeasonCode(serieData.programcode);
      } else {
        setIsFav(serieData.isfavourite == 1 ? true : false);
        let poster = `${serieData?.posterpath}/${
          serieData?.posterfilelist?.split(";")[6]
        }`;
        let posterV = `${serieData?.posterpath}/${
          serieData?.posterfilelist?.split(";")[5]
        }`;
        if (serieData.breakpoint && serieData.breakpoint != "0") {
          setSerieBookmark(serieData.breakpoint);
        }

        getSeriesAuthInfo(serieData, 14);
        if (!!episodeCode) {
          getPlayUrl(episodeCode, serieData?.columncode);
        }
      }
    }
  };

  const getVodPreviewUrl = async (programCode, columnCode) => {
    /*  const { data } = await getPreviewUrl(programCode, columnCode);
    if (data.returncode === "0") {
      setPlayUrl(data.httpsplayurl);
      setIsPlayerOpen(true);
      playerOpen.current = true;
      setTrailerPlayUrl(null);
    } else {
      setPlayUrl(null);
      if (isDirectlyPlay || isPipPlay) {
        setShowNotification(true);
      }
    } */
  };

  const getSeriesAuthInfo = async (serieData, contentType) => {
    const { data } = await doAuth(
      serieData?.programcode,
      serieData.columncode,
      contentType
    );
    if (data.returncode === "5") {
      setSerieProducts(data.productlist);
    }
    serieData.productlist = data.productlist;
    setVod(serieData);

    if (data.returncode !== "0") {
      setShowPreviewButton(true);
      if (isDirectlyPlay || isPipPlay) {
        /*  getVodPreviewUrl(serieData.programcode, serieData.columncode); */
      }
    } else {
      setShowPreviewButton(false);
    }
    getSeriesSeasons(serieData, data.returncode === "0");
  };

  const getSeriesSeasons = async (serieData, isAuth) => {
    const data = await getSeasons(serieData?.programcode);
    if (data.data.returncode == 0) {
      let seasons = data.data.data;
      if (seasons?.length) {
        setSelectedSeason(seasons[0]);
        if (serieData.breakpoint && serieData.breakpoint != "0") {
          let breatkPointArr = serieData.breakpoint.split("|");
          let bSeason = breatkPointArr[0]; //bookmarked season no
          let bEpisodeContentCode; //bookmarked episode's contentcode
          if (breatkPointArr.length >= 4) {
            bEpisodeContentCode = breatkPointArr[3];
          }
          bookmarkedSeason.current = seasons.find(
            (item) => item.seriesseason === bSeason
          );
          if (bookmarkedSeason.current) {
            getEpisodeOfSeason(
              bookmarkedSeason.current.programcode,
              false,
              bEpisodeContentCode,
              breatkPointArr[2]
            );
          }
        }
      }

      /* setSeasons(seasons); */
      if (!!seasonCode) {
        setSeasonCode(seasonCode);
      }
      if (!episodes.current?.length && isAuth && isDirectlyPlay) {
        if (seasons.length) {
          getEpisodeOfSeason(seasons[0].programcode, true);
        } else {
          /* setShowNotification(true); */
        }
      }
    }
  };

  const getEpisodeOfSeason = async (
    code,
    fromSeason = false,
    episodeContentCode = null,
    breatkPointArr = null
  ) => {
    const data = await getEpisodes(code);
    if (data.data.returncode == 0) {
      setSeasonEpisodes(data.data.data);
      episodes.current = data.data.data;

      if (episodes.current?.length) {
        if (fromSeason) {
          playFirstEpisode();
        }
        if (episodeContentCode) {
          bookmarkedEpisode.current = episodes.current.find(
            (item) => item.contentcode === episodeContentCode
          );
          if (isPipPlay || isDirectlyPlay) {
            continueBookmarkedEpisode(breatkPointArr);
          }
        }
      }
    } else {
      setShowLoading(false);
    }
  };

  const onSelectEpisode = (item) => {
    if (notInProduct.current) {
      setShowNotInProduct(true);
      showNotInProductRef.current = true;
    } else {
      setShowLoading(true);
      findNextEpisode(item);
      if (serieBookmark) {
        let arr = serieBookmark.split("|");
        if (arr[0] === bookmarkedSeason.current.seriesseason) {
          if (vod?.channelcode) {
            if (arr[3] === item?.contentcode) {
              setBookmarkTime(arr[2]);
            }
          } else if (arr[1] === item.seriesnum) {
            setBookmarkTime(arr[2]);
          }
        } else {
          setBookmarkTime(null);
        }
      }
      setBookmarkTime(item?.lastDuration);

      getEpisodeDetail(item);
    }
  };

  const getEpisodeDetail = async (item) => {
    getPlayUrl(item);
  };

  const playFirstEpisode = () => {
    findNextEpisode(episodes.current[0]);
    getEpisodeDetail(episodes.current[0]);
  };

  const continueBookmarkedEpisode = (breatkPointArr = null) => {
    setSelectedEpisode_(bookmarkedEpisode.current);
    setSelectedSeason(bookmarkedSeason.current);
    setBookmarkTime(
      breatkPointArr ? breatkPointArr : parseInt(serieBookmark.split("|")[2])
    );
    getEpisodeDetail(bookmarkedEpisode.current);
  };

  const findNextEpisode = (item) => {
    selectedEpisode.current = item;
    setSelectedEpisode_(item);
    let selectedEpisodeIndex = episodes.current.findIndex(
      (item) => item.programcode === selectedEpisode.current.programcode
    );
    if (selectedEpisodeIndex < episodes.current.length) {
      setNextEpisode(episodes.current[selectedEpisodeIndex + 1]);
    }
  };

  const getPlayUrl = async (episodeItem = null) => {
    setShowCancelButton(false);
    setWhereFrom(""); //to stop fallback from picture in picture

    let contentId;
    if (vod?.type === "MOVIE") {
      contentId = vod?.id;
    } else if (vod?.type === "SERIES") {
      contentId = episodeItem?.id ?? seasonEpisodes[0]?.id;
    }

    if (vod?.id) {
      const data = await getVodPlayUrlById(contentId);

      setPlayUrl(data.data.url);

      setIsPlayerOpen(true);
      playerOpen.current = true;

      setIsPlay((prevState) => !prevState);

      setShowLoading(false);
    }
  };

  const getParameterByName = (name, url) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  const watchNow = async () => {
    setShowWatchNow(false);
    showWatchNowRef.current = false;
    getVod(true);
    getPlayUrl();
  };

  const watchLater = () => {
    setShowWatchNow(false);
    showWatchNowRef.current = false;
    getVod(true);
  };

  const cancelRent = async () => {
    const { data } = await doUnsubscribe(vod?.mpay);
    if (data.returncode === "0") {
      getVod(true);
    } else if (data.returncode === "80200603") {
      setShowCancelPaymentError(true);
      getVod(true);
    }
    setShowCancelPopup(false);
    showCancelPopupRef.current = false;
  };

  const getThumbnails = async (cmsid, pContentId, programBegin, programEnd) => {
    const data = await getAllThumbnail(
      cmsid,
      pContentId,
      vod?.channelcode ? 9 : 4,
      programBegin,
      programEnd
    );
    if (data.data.returncode == 0 && data.data.thumbnails.length) {
      let thumbnailObj = data.data;
      let val = vod?.channelcode
        ? formatProgramThumbnails(data.data, programBegin, programEnd)
        : formatThumbnails(thumbnailObj, true);
      setThumbnails(val);
    } else {
      setThumbnails([]);
    }
  };

  const getBundleContents = async (productCode) => {
    const { data } = await getBundleVods(productCode);
    if (data[0].returncode === "0") {
      setBundleData(data[0].data);
    }
  };

  const calcDuration = () => {
    if (vod?.type === "MOVIE") {
      return prettyTimeFormatter(vod?.duration);
    } else if (vod?.type === "SERIES") {
      return `${vod?.children?.length + " " + localizationStrings.season}`;
    }
  };

  let rentalProduct;
  let estProduct;
  let rentalBundleProduct;
  let estBundleProduct;
  const renderActionButtons = () => {
    if (!vod?.isUnlocked && !!vod?.products?.length && hasLoggedIn) {
      rentalProduct = vod?.products.find((item) => item.type === "RENTAL");
      estProduct = vod?.products.find((item) => item.type === "PURCHASE");

      notInProduct.current = !(rentalProduct || estProduct);
      return (
        <div className="vod-detail-button-wrapper">
          {/* {showPreviewButton ? (
            <div
              className="vod-detail-play-button"
              style={{ cursor: !canWatch ? "not-allowed" : "" }}
              onClick={() => {
                if (canWatch) {
                  // getVodPreviewUrl(vod?.programcode, vod?.columncode);
                }
              }}
            >
              <div className="icon">
                <SinglePlayIcon />
              </div>
              <span>{localizationStrings.preview}</span>
            </div>
          ) : null} */}

          {!!rentalProduct ? (
            <div
              className="vod-detail-rent-button"
              onClick={() => {
                isRent.current = true;
                let textObj = {};
                if (!!rentalBundleProduct) {
                  getBundleContents(rentalBundleProduct.productcode);
                  setShowBundleMessage(true);
                  showBundleMessageRef.current = true;
                } else {
                  setSelectedProduct(rentalProduct);
                  if (profile.hasPin) {
                    setShowPasswordModal(true);
                    textObj.text =
                      localizationStrings.profile.enterProfilePasswordToBuyRent;
                    textObj.confirmText = localizationStrings.enter;
                    canPurchaseRef.current = true;
                    setNotificationText(textObj);
                    setCanPurchase(true);
                  } else {
                    if (!showPayment.current) {
                      setShowPayment(true);
                      showPaymentRef.current = true;
                    }
                    setCanPurchase(false);
                  }
                }
              }}
            >
              {localizationStrings.rentVod}: {rentalProduct.price} {currency}
            </div>
          ) : null}

          {!!estProduct ? (
            <div
              className="vod-detail-rent-button"
              onClick={() => {
                isRent.current = false;
                let textObj = {};
                if (!!estBundleProduct) {
                  getBundleContents(estBundleProduct.productcode);
                  setShowBundleMessage(true);
                  showBundleMessageRef.current = true;
                } else {
                  setSelectedProduct(estProduct);
                  if (profile.hasPin) {
                    setShowPasswordModal(true);
                    textObj.text =
                      localizationStrings.profile.enterProfilePasswordToBuyRent;
                    textObj.confirmText = localizationStrings.enter;
                    canPurchaseRef.current = true;
                    setNotificationText(textObj);
                    setCanPurchase(true);
                  } else {
                    if (!showPayment.current) {
                      setShowPayment(true);
                      showPaymentRef.current = true;
                    }
                    setCanPurchase(false);
                  }
                }
              }}
            >
              {localizationStrings.buyVod}: {estProduct.price} {currency}
            </div>
          ) : null}

          {notInProduct.current ? (
            <div
              className="vod-detail-play-button"
              onClick={() => {
                setShowNotInProduct(true);
                showNotInProductRef.current = true;
              }}
            >
              <div className="icon">
                <SinglePlayIcon />
              </div>
              <span>{localizationStrings.play}</span>
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div
          className="vod-detail-play-button asd"
          style={{ cursor: !canWatch ? "not-allowed" : "" }}
          onClick={() => {
            getPlayUrl();
            if (canWatch && !showLoading) {
              if (userInfo?.usertype === "1") {
                setShowLoading(true);
                if (serieBookmark && bookmarkedEpisode.current) {
                  continueBookmarkedEpisode();
                } else {
                  if (vod?.programtype === "14") {
                    getEpisodeOfSeason(selectedSeason?.programcode, true);
                  } else {
                    getPlayUrl(
                      vod?.programcode,
                      vod?.columncode,
                      vod?.physicalcontentid,
                      false
                    );
                  }
                }
              } else {
                showLoginMessageRef.current = true;
                setShowLoginMessage(true);
              }
            }
          }}
        >
          <div
            className="icon"
            style={{ marginRight: showLoading ? "6px" : "" }}
          >
            {showLoading ? (
              <div className="loading-gif">
                <img src={mainLoadingGif} />
              </div>
            ) : (
              <SinglePlayIcon />
            )}
          </div>
          <span>
            {serieBookmark || (bookmarkTime != "0" && bookmarkTime)
              ? localizationStrings.continue
              : localizationStrings.play}
          </span>
        </div>
      );
    }
  };

  useEffect(() => {
    disableBodyScroll(document.body, { reserveScrollBarGap: true });
    if (vodId) {
      getVod();
      let vodDetailBox = document.getElementById("vodDetailBox");
      if (vodDetailBox) {
        vodDetailBox.scrollTop = 0;
      }
    }

    return () => {
      enableBodyScroll(document.body);
    };
  }, [vodId, isPipPlay]);

  useEffect(() => {
    pathRef.current = path;
  }, [path]);

  useEffect(() => {
    showLoginMessageRef.current = showLoginMessage;
  }, [showLoginMessage]);
  //console.log("isDirectlyPlay", isDirectlyPlay);
  return (
    <Modal
      viewClass={`vod-modal`}
      isHide={isDirectlyPlay || isPipPlay ? true : false}
    >
      <div
        ref={modalRef}
        className="vod-detail-container"
        style={{ opacity: isDirectlyPlay || isPipPlay ? 0 : 1 }}
      >
        <div id="vodDetailBox" className="vod-detail-box">
          <div>
            <div ref={posterWrapperRef} className="poster-wrapper">
              <div className="img-wrapper">
                {trailerPlayUrl && isPlay ? (
                  <TrailerPlayer
                    playUrl={trailerPlayUrl}
                    poster={posterUrl}
                    isAudioOn={isAudioOn}
                    isPlay={isPlay}
                  />
                ) : canWatch ? (
                  <img
                    alt="vod detail poster"
                    src={posterUrl ?? defaultDetailPoster}
                  />
                ) : (
                  <ChannelControl />
                )}
              </div>
              <div
                className="close"
                onClick={() => {
                  //history.go(-2)
                  path
                    ? history.push(path)
                    : document.referrer
                    ? history.goBack()
                    : history.push("/dashboard");
                }}
              >
                <PopupCloseIcon />
              </div>
              {showEpisodes ? (
                <div
                  className="back-voddetail"
                  onClick={() => {
                    setShowEpisodes(false);
                  }}
                >
                  <BackIcon />
                </div>
              ) : null}
              <div
                className="vod-detailcontainer-absolute"
                style={{
                  top: `${posterWrapperRef.current?.offsetHeight * 0.7}px`,
                  display: !vod ? "none" : ""
                }}
              >
                <div className="vod-detail-row">
                  {!showEpisodes ? (
                    <div
                      className="vod-detail-button-wrapper"
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {renderActionButtons()}
                        {vod?.type === "SERIES" ? (
                          <div
                            className="vod-detail-rent-button"
                            onClick={() => {
                              /*  getEpisodeOfSeason(selectedSeason?.programcode); */
                              setSelectedSeason(seasons[0]);
                              setShowEpisodes(true);
                            }}
                          >
                            <div className="icon">
                              <EpisodesIcon />
                            </div>
                            <span>{localizationStrings.episodes}</span>
                          </div>
                        ) : null}
                        {vod?.rentback === "1" &&
                        (vod?.paytype === "" || vod?.paytype === "0") &&
                        showCancelButton ? (
                          <div
                            className="vod-detail-rent-button"
                            onClick={() => {
                              setShowCancelPopup(true);
                              showCancelPopupRef.current = true;
                            }}
                          >
                            {localizationStrings.payment.cancelPayment}
                          </div>
                        ) : null}
                        <FavoriteAction vod={vod} />
                        <LikeAction vod={vod} />
                        {/* <ShareAction vod={vod} /> */}
                      </div>

                      {trailerPlayUrl ? (
                        <div style={{ display: "flex" }}>
                          <div
                            className={`action-button secondary `}
                            onClick={() => {
                              setIsAudioOn(!isAudioOn);
                              setTrailerAudioOnGlobal(!isAudioOn);
                            }}
                          >
                            <span className="icon">
                              {isAudioOn ? <AudioOnIcon /> : <AudioOffIcon />}
                            </span>
                          </div>
                          <div
                            className={`action-button secondary `}
                            onClick={() => {
                              setIsPlay(!isPlay);
                            }}
                          >
                            <span className="icon">
                              {isPlay ? <PauseIcon /> : <PlayerPlayIcon />}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div
                      className="vod-detail-button-wrapper"
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <label className="episode-text">
                        {localizationStrings.episodes}
                      </label>
                      <div
                        className={`vod-detail-select-box ${
                          showOptions ? "opened" : ""
                        }`}
                        onClick={() => setShowOptions(!showOptions)}
                      >
                        <span>
                          {selectedSeason?.seasonNo}.{" "}
                          {localizationStrings.season}
                        </span>
                        <div className="icon arrow-down"></div>

                        <div className={`options ${showOptions ? "show" : ""}`}>
                          {seasons?.map((item) => {
                            return (
                              <div
                                key={item?.id}
                                className="option"
                                onClick={() => {
                                  if (selectedSeason?.id !== item?.id) {
                                    setSelectedSeason(item);
                                    /* getEpisodeOfSeason(item.programcode); */
                                  }
                                }}
                              >
                                <span>
                                  {item?.seasonNo}. {localizationStrings.season}
                                </span>
                                <div className="icon arrow-down transparent"></div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}

                  {showEpisodes ? (
                    <div className="episode-container">
                      {seasonEpisodes?.map((item, index) => {
                        let bookmark = null;
                        if (
                          bookmarkedEpisode.current &&
                          bookmarkedEpisode.current?.programcode ===
                            item.programcode
                        ) {
                          bookmark = serieBookmark.split("|")[2];
                        }
                        return (
                          <EpisodeItem
                            key={item?.id}
                            episode={item}
                            isLast={seasonEpisodes?.length - 1 === index}
                            loading={showLoading}
                            onSelectEpisode={(item) => {
                              if (hasLoggedIn) {
                                onSelectEpisode(item);
                              } else {
                                showLoginMessageRef.current = true;
                                setShowLoginMessage(true);
                              }
                            }}
                            bookmark={bookmark}
                          />
                        );
                      })}
                    </div>
                  ) : null}
                </div>

                <div
                  className="vod-detail-row"
                  style={{ display: showEpisodes ? "none" : "" }}
                >
                  <div className="vod-info-wrapper">
                    <div className="vod-info l-font">{calcDuration()}</div>
                    <div className="vod-info-seperator"></div>
                    <div className="vod-info l-font">{vod?.year}</div>

                    <div className="ratings" style={{ marginLeft: "25px" }}>
                      <div className="rating">
                        {vod?.maturityRating?.age === "G" ? (
                          <RtukGeneralIcon />
                        ) : vod?.maturityRating?.age === "7+" ? (
                          <RtukSevenAgeIcon />
                        ) : vod?.maturityRating?.age === "13+" ? (
                          <RtukThirteenAgeIcon />
                        ) : vod?.maturityRating?.age === "18+" ? (
                          <RtukAgeIcon />
                        ) : null}
                      </div>
                      {getParentalAdvisoryElements(
                        vod?.maturityRating?.categories
                      )}
                    </div>

                    <div className="imdb-point" style={{ marginLeft: "25px" }}>
                      IMDB {vod?.imdbRating}
                    </div>
                  </div>
                </div>
                <div
                  className="vod-detail-row synopsis-box"
                  style={{ display: showEpisodes ? "none" : "" }}
                >
                  {vod?.summary}
                </div>

                {!showEpisodes ? (
                  <div className="metadata-container">
                    <div>
                      <div className="title">{localizationStrings.info}</div>
                      <div className="vod-box">
                        <div className="vertical-poster">
                          <img
                            src={verticalPosterPath ?? defaultPosterVertical}
                          />
                        </div>
                        <div className="info-box">
                          <div className="title">{vod?.title}</div>
                          {vod?.originalTitle &&
                          vod?.title !== vod?.originalTitle ? (
                            <div className="org-title">
                              {vod?.originalTitle}
                            </div>
                          ) : null}

                          <div className="info">
                            <label>{localizationStrings.genre}:</label>
                            <span>
                              {vod?.genre?.split(";")?.map((item, index) => {
                                return (
                                  <NavLink
                                    key={item?.id}
                                    className="link"
                                    to={{
                                      pathname: `/dashboard/search/${item}`
                                    }}
                                  >
                                    {item}
                                    {`${
                                      vod?.genre?.split(";").length - 1 ===
                                      index
                                        ? ""
                                        : ", "
                                    }`}
                                  </NavLink>
                                );
                              })}
                            </span>
                          </div>

                          <div
                            className="info"
                            style={{
                              display: !vod?.director?.name ? "none" : ""
                            }}
                          >
                            <label>{localizationStrings.director}:</label>
                            <span>
                              {vod?.director?.name
                                .split(";")
                                ?.map((item, index) => {
                                  return (
                                    <NavLink
                                      key={item?.id}
                                      className="link"
                                      to={{
                                        pathname: `/dashboard/search/${item}`
                                      }}
                                    >
                                      {item}
                                      {`${
                                        vod?.director?.name.split(";").length -
                                          1 ===
                                        index
                                          ? ""
                                          : ", "
                                      }`}
                                    </NavLink>
                                  );
                                })}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: "20px",
                        display: !vod?.actors ? "none" : ""
                      }}
                    >
                      <div className="title">{localizationStrings.cast}</div>
                      <CastCarousel casts={vod?.actors} history={history} />
                    </div>
                  </div>
                ) : null}

                {vod && !showEpisodes ? (
                  <div
                    className="related-box"
                    style={{ marginTop: "50px", marginBottom: "100px" }}
                  >
                    <Carousel
                      slidesToScroll={4}
                      slidesToShow={4}
                      column={{
                        columncode: "vodrelated",
                        columnname: localizationStrings.relatedVods
                      }}
                      vod={vod}
                      dontUseGrid={true}
                      padding="0"
                      onHoverItem={(isShow, data) => {
                        if (isShow) {
                          previewData.current = data;
                        }
                        setShowMiniVodDetail(isShow);
                      }}
                      isVodRelated={true}
                    />
                  </div>
                ) : null}
              </div>

              <div className="gradient"></div>
            </div>
          </div>
        </div>
        {showWatchNow ? (
          <Notification
            text={localizationStrings.watchNowText}
            confirmText={localizationStrings.watchNow}
            notConfirmText={localizationStrings.watchLater}
            onClickConfirm={() => watchNow()}
            onClickNotConfirm={() => watchLater()}
          />
        ) : null}
        {showMiniVodDetail ? (
          <MiniVodDetail
            vodData={previewData.current}
            closeModal={() => {
              setShowMiniVodDetail(false);
            }}
            isVodDetail={true}
          />
        ) : null}
      </div>
      {isPlayerOpen ? (
        <Modal viewClass={`player-modal`}>
          <VideoPlayer
            key="vod-player"
            options={playerOptions}
            src={playUrl}
            vod={vod}
            thumbnails={thumbnails}
            isTimeshiftEnable={true}
            addContentToFavorite={() => {}}
            removeContentFromFavorite={() => {}}
            setBookmarkTime={(val) => {
              setBookmarkTime(val);
            }}
            bookmarkTime={bookmarkTime}
            showAd={showAd}
            closePlayer={(val) => {
              if (val) {
                getVod();
                if (isDirectlyPlay || isPipPlay) {
                  history.push(path);
                }

                setIsPlayerOpen(false);
                playerOpen.current = false;
                if (trailerUrlRef.current) {
                  setTrailerPlayUrl(trailerUrlRef.current);
                }
              }
            }}
            nextEpisode={nextEpisode}
            selectedSeason={selectedSeason}
            selectedEpisode={selectedEpisode_}
            playNextEpisode={() => {
              if (!!nextEpisode) {
                getEpisodeDetail(nextEpisode);
                findNextEpisode(nextEpisode);
              }
            }}
            isFav={isFav}
            isPreview={showPreviewButton}
            isCutvVod={!!vod?.channelcode}
            poster={posterUrl}
          />
        </Modal>
      ) : null}

      {showProductImage ? (
        <Notification
          text={localizationStrings.outOfProduct}
          confirmText={localizationStrings.close}
          notConfirmText=""
          onClickConfirm={() => setShowProductImage(false)}
        />
      ) : null}
      {showCancelPaymentError ? (
        <Notification
          text={localizationStrings.cancelPaymentExpired}
          confirmText={localizationStrings.close}
          onClickConfirm={() => setShowCancelPaymentError(false)}
        />
      ) : null}
      {showCancelPopup ? (
        <Notification
          text={localizationStrings.askCancelPayment}
          confirmText={localizationStrings.yes}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={() => {
            cancelRent();
          }}
          onClickNotConfirm={() => {
            setShowCancelPopup(false);
            showCancelPopupRef.current = false;
          }}
        />
      ) : null}
      {showBundleMessage ? (
        <Notification
          text={localizationStrings.formatString(
            localizationStrings.bundleContentText,
            {
              action: (
                <span style={{ color: "white", fontWeight: "bold" }}>
                  {isRent.current
                    ? localizationStrings.payment.rent
                    : localizationStrings.payment.purchase}
                </span>
              ),
              contentName: (
                <span style={{ color: "white", fontWeight: "bold" }}>
                  {vod?.programname}
                </span>
              )
            }
          )}
          confirmText={localizationStrings.yes}
          notConfirmText={localizationStrings.no}
          onClickConfirm={() => {
            setShowBundleMessage(false);
            showBundleMessageRef.current = false;
            let product;
            if (isRent.current) {
              product = rentalBundleProduct;
            } else {
              product = estBundleProduct;
            }
            setSelectedProduct(product);
            setShowPayment(true);
            showPaymentRef.current = true;
          }}
          onClickNotConfirm={() => {
            setShowBundleMessage(false);
            showBundleMessageRef.current = false;
            let product;

            if (isRent.current) {
              product = rentalProduct;
            } else {
              product = estProduct;
            }
            setSelectedProduct(product);
            setShowPayment(true);
            showPaymentRef.current = true;
          }}
          data={bundleData}
        />
      ) : null}

      {showNotification ? (
        <Notification
          text={localizationStrings.contentError}
          confirmText={localizationStrings.close}
          onClickConfirm={() => setShowNotification(false)}
        />
      ) : null}

      {showNotInProduct ? (
        <Notification
          text={localizationStrings.outOfProduct}
          confirmText={localizationStrings.packages}
          icon={<PackageIcon />}
          onClickConfirm={() => history.push("/dashboard/package")}
          onClickNotConfirm={() => {
            setShowNotInProduct(false);
            notInProduct.current = false;
          }}
          showCloseButton={true}
        />
      ) : null}

      {canPurchase ? (
        <Notification
          text={notificationText?.text}
          confirmText={notificationText?.confirmText}
          onClickConfirm={() => {
            //setSelectedProduct(estProduct);
            canPurchaseRef.current = false;
            setCanPurchase(false);
          }}
          onClickNotConfirm={() => {
            setCanPurchase(false);
          }}
          isPassword={showPasswordModal}
          icon={<BigLockIcon />}
          sendResult={(val) => {
            console.log(val);
            if (val) {
              if (!showPayment.current) {
                setShowPayment(true);
                showPaymentRef.current = true;
              }
              setCanPurchase(false);
            }
          }}
          showCloseButton={true}
        />
      ) : null}
      {showPayment ? (
        <Payment
          vod={vod}
          product={selectedProduct}
          closePayment={(whatchNow) => {
            setShowPayment(false);
            showPaymentRef.current = false;
            showWatchNowRef.current = whatchNow;
            setShowWatchNow(whatchNow);
          }}
        />
      ) : null}
    </Modal>
  );
};

export default VodDetail;
