import React, { useState } from "react";
import localizationStrings from "../../../statics/localization";
import Notification from "../../notification";
import PlayerExitIcon from "../../../assets/icons/svg/PlayerExitIcon";
import { getVodPlayUrlById } from "../../../api/vod/vod";

const ExitButton = ({ player, goBack, showExitNotification, contentId }) => {
  const [showExitContainer, setShowExitContainer] = useState(false);

  const handleSendExitTime = async () => {
    if (contentId) {
      let exitTime = Math.floor(player.cache_.currentTime);
      const data = await getVodPlayUrlById(contentId, exitTime);
      console.log({ data });
    }
  };

  const clickConfirm = () => {
    handleSendExitTime();
    setShowExitContainer(false);
    showExitNotification(false);
    goBack();
  };

  const clickNotConfirm = () => {
    setShowExitContainer(false);
    showExitNotification(false);
  };
  return (
    <div>
      {showExitContainer ? (
        <Notification
          text={localizationStrings.exitPlayerText}
          confirmText={localizationStrings.yes}
          notConfirmText={localizationStrings.no}
          onClickConfirm={clickConfirm}
          onClickNotConfirm={clickNotConfirm}
          dontUsePortal={true}
        />
      ) : (
        <button
          className="exit-button"
          onClick={() => {
            setShowExitContainer(true);
            showExitNotification(true);
            player.pause();
          }}
        >
          <PlayerExitIcon />
        </button>
      )}
    </div>
  );
};

export default ExitButton;
