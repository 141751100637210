class ActionProvider {
  constructor(createChatbotMessage, setStateFunc) {
    this.createChatbotMessage = createChatbotMessage;
    this.setState = setStateFunc;
  }

  handleMessage() {
    const botMessage = this.createChatbotMessage(
      "Mesajınıza cevap veriyorum..."
    );
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, botMessage]
    }));
  }
}

export default ActionProvider;
