import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import {
  deleteNpvrRecord,
  deleteSerieNpvrRecord,
  getRecordedList,
  getWillBeRecordedList
} from "../../api/npvr/npvr";
import ModalPortal from "../../components/modalPortal";
import ProgramDetail from "../../components/programDetail";
import RecordItem from "../../components/recordItem";
import localizationStrings from "../../statics/localization";
import { sendCustomNpvrReport } from "../../utils/reporting/customReporting";
import recordImg from "../../assets/icons/svg/Icon awesome-angle-right.svg";
import { useHistory, useLocation } from "react-router-dom";
import TrashIcon from "../../assets/icons/svg/TrashIcon";
import ToBeReacordIcon from "../../assets/icons/svg/ToBeReacordIcon";
import RecordedIcon from "../../assets/icons/svg/RecordedIcon";
import AngleRightIcon from "../../assets/icons/svg/AngleRightIcon";
import EyeIcon from "../../assets/icons/svg/EyeIcon";
import useOutsideClick from "../../utils/useOutsideClick";
import { SessionContext } from "../../context/SessionContext";
import Notification from "../../components/notification";

const slidesToShow = 5;
const slidesToScroll = 5;
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: slidesToShow,
  slidesToScroll: slidesToScroll,
  arrows: false,
  draggable: false
};

const Record = ({
  onHoverProgramItem,
  recordRefreshCounter,
  selectedRecordItem,
  setSelectedRecordItem
}) => {
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [selectedProgramCode, setSelectedProgramCode] = useState(null);
  const [selectedProgramdDetail, setSelectedProgramDetail] = useState(null);
  const { setSelectedCutvProgram, selectedChannel, userFrameConfig, userInfo } =
    useContext(SessionContext);
  const deleteCount = useRef(0);
  const allRecords = useRef([]);
  const slider = useRef(null);
  const filterType = useRef("-1");
  const optionRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const timeoutRef = useRef(null);

  useOutsideClick(optionRef, () => {
    setShowOptions(false);
  });

  const getRecords = async () => {
    const data = await getRecordedList();
    if (data.status === 200) {
      let arr = data.data;
      allRecords.current = arr;
      setRecords(arr);
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        slider.current && slider.current.slickGoTo(0);
      }, 200);
    } else {
      setRecords([]);
    }
    sendCustomNpvrReport(
      null,
      null,
      null,
      2,
      data.data.returncode,
      data.data.errormsg
    );
  };

  const onSelectRecord = (item) => {
    setSelectedRecord(item);
    setShowModal(true);
    setSelectedProgramCode(item?.epgProgramId);
    setSelectedProgramDetail(item);
  };

  const closeRecordModal = (isRefresh) => {
    if (isRefresh) {
      setRecords([]);
      getRecords();
    }
    setShowModal(false);
  };

  const getAllRecords = async (type) => {
    let records = [];
    switch (type) {
      case "1":
        const recordedData = await getRecordedList();
        if (recordedData.status === 200) {
          records = recordedData.data;
        }
        break;
      case "0":
        const willBeRecordedData = await getWillBeRecordedList();
        if (willBeRecordedData.status === 200) {
          records = willBeRecordedData.data;
        }
        break;
      default:
        const recordedDatatemp = await getRecordedList();
        if (recordedDatatemp.status === 200) {
          records = recordedDatatemp.data;
        }
        const willBeRecordedDatatemp = await getWillBeRecordedList();
        if (willBeRecordedDatatemp.status === 200 && willBeRecordedDatatemp.data.length > 0) {
          records = [...records, ...willBeRecordedDatatemp.data];
        }
        break;
    }
    allRecords.current = records;
    setRecords(records);
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      slider.current && slider.current.slickGoTo(0);
    }, 200);
  };

  const filterRecords = (type) => {
    // let filteredRecords = [];
    // if (type === "-1") {
    //   filteredRecords = allRecords.current;
    // } else if (type === "1") {
    //   filteredRecords = allRecords.current.filter((item) => item.status == "1");
    // } else {
    //   filteredRecords = allRecords.current.filter((item) => item.status != "1");
    // }

    filterType.current = type;
    //setRecords(filteredRecords);
    // if (!records?.length) {
    setTimeout(() => {
      slider.current.slickGoTo(0);
    }, 200);
    // }
  };

  const deleteSingleRecord = async (item, isRemoveAll) => {
    const { data } = await deleteNpvrRecord(item?.prevuecode);
    if (data.returncode === "0") {
      if (isRemoveAll) {
        deleteCount.current++;
        if (deleteCount.current === allRecords.current.length) {
          allRecords.current = [];
          setRecords([]);
        }
      } else {
        getRecords();
      }
    }
    sendCustomNpvrReport(
      item?.prevuecode,
      item.channelcode,
      "",
      0,
      data.returncode,
      data.errormsg
    );
  };

  const deleteSeriesRecord = async (item, isRemoveAll) => {
    const { data } = await deleteSerieNpvrRecord(item?.seriesheadid);
    if (data.returncode === "0") {
      if (isRemoveAll) {
        deleteCount.current++;
        if (deleteCount.current === allRecords.current.length) {
          allRecords.current = [];
          setRecords([]);
        }
      } else {
        getRecords();
      }
    }
    sendCustomNpvrReport(
      item.prevuecode,
      item.channelcode,
      item.seriesheadid,
      0,
      data.returncode,
      data.errormsg
    );
  };

  const deleteAllRecords = () => {
    setShowNotification(false);

    allRecords.current.forEach((item) => {
      if (item && !!item.seriesheadid && item.seriesheadid !== "0") {
        deleteSeriesRecord(item, true);
      } else {
        deleteSingleRecord(item, true);
      }
    });
  };

  const renderedItems = records.map((item, index) => {
    return (
      <RecordItem
        key={item?.npvrcode}
        item={item}
        title={item?.prevuename}
        onSelect={onSelectRecord}
        deleteRecord={(item) => {
          if (item?.seriesheadid != "0" && !!item?.seriesheadid) {
            deleteSeriesRecord(item, false);
          } else {
            deleteSingleRecord(item, false);
          }
        }}
        isFirst={index == 0}
        onHoverProgramItem={onHoverProgramItem}
      />
    );
  });

  // useEffect(() => {
  //   getRecords();
  // }, [recordRefreshCounter]);

  useEffect(() => {
    if (filterType.current) {
      getAllRecords(filterType.current);
    }
  }, [filterType.current, recordRefreshCounter]);

  useEffect(() => {
    if (selectedRecordItem) {
      setShowModal(true);
    }
    setSelectedRecord(selectedRecordItem?.record);
  }, [selectedRecordItem]);

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div
      className="content-carousel"
      style={{
        display: !allRecords.current?.length ? "none" : "",
        minHeight: "15vw"
      }}
    >
      <label
        className="label-title"
        onClick={() =>
          history.push(
            `/dashboard/genre/record/${localizationStrings.myRecords}`
          )
        }
      >
        {localizationStrings.myRecords}
        <img className="see-all-icon" src={recordImg} />
      </label>

      <div
        ref={optionRef}
        className="dot-options"
        style={{ top: 0 }}
        onClick={(e) => {
          e.stopPropagation();
          setShowOptions(!showOptions);
        }}
      >
        <div className="dots-wrapper" style={{ right: "0px" }}>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>

          <div className={`options-box ${showOptions ? "show" : ""}`}>
            <div className="tri-angle"></div>
            <div className="options">
              {filterType.current !== "1" ? (
                <div className="option" onClick={() => filterRecords("1")}>
                  <RecordedIcon />
                  <span>{localizationStrings.recordeds}</span>
                </div>
              ) : null}

              {filterType.current !== "0" ? (
                <div className="option" onClick={() => filterRecords("0")}>
                  <ToBeReacordIcon />
                  <span>{localizationStrings.willRecord}</span>
                </div>
              ) : null}
              {filterType.current !== "-1" ? (
                <div className="option" onClick={() => filterRecords("-1")}>
                  <EyeIcon />
                  <span>{localizationStrings.seeAll}</span>
                </div>
              ) : null}
              <div className="option" onClick={() => setShowNotification(true)}>
                <TrashIcon />
                <span>{localizationStrings.deleteAll}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slider-row">
        <div className="slick-wrapper">
          <Slider ref={slider} {...settings}>
            {renderedItems}
          </Slider>
        </div>
        <div
          className="direction-button prev record"
          style={records.length > slidesToShow ? {} : { visibility: "hidden" }}
          onClick={() => slider.current.slickPrev()}
        >
          <span>
            <AngleRightIcon />
          </span>
        </div>

        <div
          className="direction-button next record"
          style={records.length > slidesToShow ? {} : { visibility: "hidden" }}
          onClick={() => slider.current.slickNext()}
        >
          <AngleRightIcon />
        </div>
      </div>

      {showModal ? (
        <ModalPortal viewClass="vod-modal">
          <ProgramDetail
            prevueCode={selectedProgramCode}
            closeModal={() => setShowModal(false)}
            fromLive={true}
            setSelectedCutvProgram={setSelectedCutvProgram}
            selectedChannel={selectedChannel}
            userFrameConfig={userFrameConfig}
            userInfo={userInfo}
            location={location}
            history={history}
            programData={selectedProgramdDetail}
          />
        </ModalPortal>
      ) : null}

      {showNotification ? (
        <Notification
          text={localizationStrings.deleteRecordConfirmText}
          confirmText={localizationStrings.delete}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={() => {
            deleteAllRecords();
          }}
          onClickNotConfirm={() => setShowNotification(false)}
        />
      ) : null}
    </div>
  );
};

export default Record;
