import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect
} from "react";
import { SessionContext } from "../../context/SessionContext";
import { getLtvChannels } from "../../api/ltv/ltv";
import localizationStrings from "../../statics/localization";
import {
  addLockToChannel,
  deleteChannelLock
} from "../../api/common/commonApi";
import PasswordModal from "../passwordModal";
import { useHistory } from "react-router-dom";
import Notification from "../notification";
import { addFavorite, deleteFavorite } from "../../api/common/commonApi";
import { sendCustomFavoriteReport } from "../../utils/reporting/customReporting";
import ReturnIcon from "../../assets/icons/svg/ReturnIcon";
import { getColumnList } from "../../api/config/configApi";
import TvFavoriteIcon from "../../assets/icons/svg/TvFavoriteIcon";
import TvLockIcon from "../../assets/icons/svg/TvLockIcon";
import BigLockIcon from "../../assets/icons/svg/BigLockIcon";
import useOutsideClick from "../../utils/useOutsideClick";

const LockedChannel = () => {
  const [channels, setChannels] = useState(null);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [askAdminPin, setAskAdminPin] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(3);
  const [genres, setGenres] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [showGenreOptions, setShowGenreOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const allChannels = useRef(null);
  const option1Ref = useRef(null);
  const option2Ref = useRef(null);
  const {
    userFrameConfig,
    profile,
    path,
    adminProfile,
    setIsChangeLockChannel,
    isChangeLockChannel,
    isChildProfile
  } = useContext(SessionContext);
  const history = useHistory();

  useOutsideClick(option1Ref, () => {
    setShowGenreOptions(false);
  });

  useOutsideClick(option2Ref, () => {
    setShowFilterOptions(false);
  });

  const getChannelItems = async (columnCode) => {
    //kanal ögelerini al
    const data = await getLtvChannels(columnCode, 1, 500);
    if (data.data.returncode == 0) {
      allChannels.current = data.data.data.sort((a, b) => {
        return parseInt(a?.usermixno) - parseInt(b?.usermixno);
      });
      filterChannel(selectedFilter);
    } else {
      // alert("channel list error, please close your browser and try again");
    }
  };

  const getGenres = async () => {
    const data = await getColumnList(userFrameConfig?.TV_Column);
    if (data.data.returncode === "0") {
      let arr = data.data.data;
      setGenres(arr);
      let colCode = isChildProfile
        ? userFrameConfig?.ChildMode_TV_Column_All
        : userFrameConfig?.TV_Column_All;
      setSelectedGenre(arr.find((item) => item.columncode === colCode));
    }
  };

  const clickConfirm = () => {
    setShowNotification(false);
    history.push(
      `/profile/${
        askAdminPin ? adminProfile?.profilecode : profile?.profilecode
      }?page=1`
    );
  };

  const clickNotConfirm = () => {
    setShowNotification(false);
    history.push(`${path}/settings/main`);
  };

  const filterChannel = (type) => {
    let filtered = [];
    setSelectedFilter(type);
    if (type === 1) {
      filtered = allChannels.current.filter((item) => item.islocked === "1");
    } else if (type === 2) {
      filtered = allChannels.current.filter((item) => item.isfavourite === "1");
    } else if (type === 3) {
      filtered = allChannels.current;
    } else {
      return;
    }
    setChannels(filtered);
  };

  useLayoutEffect(() => {
    if (isChildProfile) {
      let arr = [
        {
          columnname: localizationStrings.child,
          columncode: userFrameConfig?.ChildMode_TV_Column_All
        },
        {
          columncode: "fav-channel",
          columnname: localizationStrings.favChannel
        }
      ];
      setSelectedGenre();
      setGenres(arr);
    } else {
      getGenres();
    }
  }, [userFrameConfig]);

  useEffect(() => {
    if (userFrameConfig) {
      let columnCode = isChildProfile
        ? userFrameConfig?.ChildMode_TV_Column_All
        : userFrameConfig?.TV_Column_All;
      getChannelItems(columnCode);
    }
    return () => {
      if (path?.includes("/livetv")) {
        setIsChangeLockChannel(
          isChangeLockChannel ? (isChangeLockChannel === "1" ? "2" : "1") : "1"
        );
      }
    };
  }, [userFrameConfig]);

  useEffect(() => {
    if (userFrameConfig && profile && adminProfile) {
      if (profile?.profileroleid === userFrameConfig.Kids_Profile_Id) {
        setAskAdminPin(true);
        if (adminProfile.havepwd === "1") {
          setShowPasswordModal(true);
        } else {
          setShowNotification(true);
        }
      } else if (profile.havepwd === "1") {
        setShowPasswordModal(true);
      } else {
        setShowNotification(true);
      }
    }
  }, [profile, userFrameConfig, adminProfile]);

  const renderedItems = channels?.map((item, index) => {
    return (
      <LockChannelItem
        key={item.channelcode}
        item={item}
        index={index}
        setLockChannelCode={(code, locked) => {
          setChannels((prevState) => {
            const newArray = Array.from(prevState);
            let index = newArray.findIndex((item) => item.channelcode == code);

            if (index != -1) {
              newArray[index].islocked = locked;
            }

            return newArray;
          });
        }}
        setFavChannelCode={(code, isFav) => {
          setChannels((prevState) => {
            const newArray = Array.from(prevState);
            let index = newArray.findIndex((item) => item.channelcode == code);

            if (index != -1) {
              newArray[index].isfavourite = isFav;
            }

            return newArray;
          });
        }}
      />
    );
  });

  return (
    <div className="settings-container">
      <div
        className="settings-icon"
        onClick={() => {
          history.push(`/dashboard/settings`);
        }}
      >
        <ReturnIcon />
        <label>{localizationStrings.favAndLock}</label>
      </div>
      <div className="select-box-row">
        <div
          ref={option1Ref}
          className={`settings-select-box ${showGenreOptions ? "opened" : ""}`}
          onClick={() => setShowGenreOptions(!showGenreOptions)}
        >
          <span>{selectedGenre?.columnname}</span>
          <div className="icon arrow-down"></div>
          <div
            className={`primary-select-box-options ${
              showGenreOptions ? "show" : ""
            }`}
            style={{ top: "3.5vw" }}
          >
            {genres?.map((item) => {
              return (
                <div
                  className="option"
                  onClick={() => {
                    setSelectedGenre(item);
                    getChannelItems(item.columncode);
                  }}
                >
                  <div
                    className={`option-text ${
                      selectedGenre?.columncode === item.columncode
                        ? "selected"
                        : ""
                    }`}
                  >
                    {item?.columnname}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div
          ref={option2Ref}
          className={`settings-select-box ${showFilterOptions ? "opened" : ""}`}
          onClick={() => setShowFilterOptions(!showFilterOptions)}
        >
          <span>
            {selectedFilter === 1
              ? localizationStrings.lockedChannels
              : selectedFilter === 2
              ? localizationStrings.favChannel
              : localizationStrings.filter}
          </span>
          <div className="icon arrow-down"></div>
          <div
            className={`primary-select-box-options ${
              showFilterOptions ? "show" : ""
            }`}
            style={{ top: "3.5vw" }}
          >
            <div
              className="option"
              onClick={() => filterChannel(1)} // 1 means locked channel
            >
              <div
                className={`option-text ${
                  selectedFilter === 1 ? "selected" : ""
                }`}
              >
                {localizationStrings.lockedChannels}
              </div>
            </div>
            <div
              className="option"
              onClick={() => filterChannel(2)} // 2 means fav channels
            >
              <div
                className={`option-text ${
                  selectedFilter === 2 ? "selected" : ""
                }`}
              >
                {localizationStrings.favChannel}
              </div>
            </div>
            <div
              className="option"
              style={{
                borderRadius: "0 0 4px 4px"
              }}
              onClick={() => filterChannel(3)} // 3 means all channels
            >
              <div
                className={`option-text ${
                  selectedFilter === 3 ? "selected" : ""
                }`}
              >
                {localizationStrings.searchFilters.all}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="list-history">
        {renderedItems}
        {channels !== null && !channels?.length ? (
          <div className="not-found">{localizationStrings.noChannelFound}</div>
        ) : null}
      </div>

      {showPasswordModal ? (
        <Notification
          text={
            askAdminPin
              ? localizationStrings.askAdminPin
              : localizationStrings.askProfilePin
          }
          confirmText={localizationStrings.enter}
          onClickConfirm={() => {}}
          onClickNotConfirm={() => {
            setShowPasswordModal(false);
            history.push(`/dashboard/settings`);
          }}
          showCloseButton={true}
          isPassword={true}
          icon={<BigLockIcon />}
          sendResult={(val) => {
            if (!val) {
              history.push(`${path}/settings/main`);
            }
            setShowPasswordModal(false);
          }}
          profileCode={
            askAdminPin ? adminProfile.profilecode : profile.profilecode
          }
        />
      ) : null}
      {showNotification ? (
        <Notification
          text={localizationStrings.createProfilePinCodeText}
          confirmText={localizationStrings.createProfilePinCode}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={clickConfirm}
          onClickNotConfirm={clickNotConfirm}
        />
      ) : null}
    </div>
  );
};

const LockChannelItem = ({ item, setLockChannelCode, setFavChannelCode }) => {
  const [isLocked, setIsLocked] = useState(false);
  const [isFav, setIsFav] = useState(false);

  const lockChannel = async (code) => {
    setIsLocked(true);

    const data = await addLockToChannel(code);
    setLockChannelCode(code, "1");
    if (data.data.returncode !== "0") {
      setIsLocked(false);
    }
  };

  const unLockChannel = async (code) => {
    setIsLocked(false);

    const data = await deleteChannelLock(code);
    setLockChannelCode(code, "0");

    if (data.data.returncode !== "0") {
      setIsLocked(true);
    }
  };

  useEffect(() => {
    if (item) {
      setIsFav(item?.isfavourite == 1 ? true : false);
      setIsLocked(item?.islocked == 1 ? true : false);
    }
  }, [item]);

  const addChannelToFavorite = async (item) => {
    setIsFav(true);
    const data = await addFavorite(5, item.channelcode, item.channelname);
    setFavChannelCode(item.channelcode, "1");

    sendCustomFavoriteReport(
      5,
      item.channelcode,
      item.columncode,
      null,
      1,
      data.data.returncode,
      data.data.errormsg
    );
    if (data.data.returncode !== "0") {
      setIsFav(false);
    }
  };

  const deleteChannelFromFavorite = async (item) => {
    setIsFav(false);
    const data = await deleteFavorite(
      5,
      item.channelcode,
      item.columncode,
      item.channelname
    );
    setFavChannelCode(item.channelcode, "0");

    sendCustomFavoriteReport(
      5,
      item.channelcode,
      item.columncode,
      null,
      0,
      data.data.returncode,
      data.data.errormsg
    );
    if (data.data.returncode !== "0") {
      setIsFav(true);
    }
  };

  return (
    <div className="favlockchannel-list-item" style={{ width: "100%" }}>
      <div className="favlockchannel-logo">
        <img
          className="logo"
          alt={item.channelname}
          src={`${item.posterpath}/${item?.posterfilelist?.split(";")[0]}`}
        />
      </div>
      <div className="seperate"></div>
      <div className="favlockchannel-info">
        <div className="favlockchannel-name">{item?.channelname}</div>
      </div>
      <div className="icons">
        <div
          className={`icon ${isFav ? "fill-red" : ""}`}
          style={{}}
          onClick={(e) => {
            isFav
              ? deleteChannelFromFavorite(item)
              : addChannelToFavorite(item);
          }}
        >
          <TvFavoriteIcon />
        </div>

        <div
          className={`icon ${isLocked ? "fill-yellow" : ""}`}
          style={{ marginLeft: "15px", marginRight: "30.5px" }}
          onClick={() =>
            isLocked
              ? unLockChannel(item.channelcode)
              : lockChannel(item.channelcode)
          }
        >
          <TvLockIcon />
        </div>
      </div>
    </div>
  );
};

export default LockedChannel;
