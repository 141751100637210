import React, { useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../../context/SessionContext";
import { getUserPurchaseHistory } from "../../api/user/user";
import localizationStrings from "../../statics/localization";
import moment from "moment";
import { getColectAgent } from "../../statics/config";
import { reportingPageEvent } from "../../statics/reportEventId";
import { useHistory } from "react-router-dom";
import ReturnIcon from "../../assets/icons/svg/ReturnIcon";
import useOutsideClick from "../../utils/useOutsideClick";

const PurchaseHistory = () => {
  const [showedPurchaseHistory, setShowedPurchaseHistory] = useState([]);
  const [showProductTypeFilter, setShowProductTypeFilter] = useState(false);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState(3);
  const [selectedDate, setSelectedDate] = useState(null);
  const { userFrameConfig } = useContext(SessionContext);
  const history = useHistory();
  const purchaseHistory = useRef([]);
  const option1Ref = useRef(null);
  const option2Ref = useRef(null);

  useOutsideClick(option1Ref, () => {
    setShowProductTypeFilter(false);
  });

  useOutsideClick(option2Ref, () => {
    setShowDateFilter(false);
  });

  const filterDateItems = [
    {
      id: 1,
      label: localizationStrings.formatString(
        localizationStrings.purchaseFilter,
        {
          count: "1",
          format: localizationStrings.payment.month
        }
      ),
      date: moment().subtract(1, "months")
    },
    {
      id: 2,
      label: localizationStrings.formatString(
        localizationStrings.purchaseFilter,
        {
          count: "3",
          format: localizationStrings.payment.month
        }
      ),
      date: moment().subtract(3, "months")
    },
    {
      id: 3,
      label: localizationStrings.formatString(
        localizationStrings.purchaseFilter,
        {
          count: "6",
          format: localizationStrings.payment.month
        }
      ),
      date: moment().subtract(6, "months")
    },
    {
      id: 4,
      label: localizationStrings.formatString(
        localizationStrings.purchaseFilter,
        {
          count: "1",
          format: localizationStrings.payment.year
        }
      ),
      date: moment().subtract(1, "years")
    },
    {
      id: 5,
      label: localizationStrings.formatString(
        localizationStrings.purchaseFilter,
        {
          count: "2",
          format: localizationStrings.payment.year
        }
      ),
      date: moment().subtract(2, "years")
    }
  ];

  const getPurchaseHistory = async () => {
    const { data } = await getUserPurchaseHistory();
    if (data.returncode === "0") {
      if (data.data.order) {
        purchaseHistory.current = data.data.order.concat(data.data.consume);
        filterPurchaseData(filterDateItems[2].date);
      }
    }
  };

  const filterPurchaseData = (date) => {
    let filtered = purchaseHistory.current.filter((item) => {
      if (moment(item.begintime, "DD/MM/YYYY HH:mm").isSameOrAfter(date))
        return item;
    });
    setShowedPurchaseHistory(filtered);
  };

  useEffect(() => {
    setSelectedDate(filterDateItems[2]);
    getPurchaseHistory();
    // getPaidHistory();
  }, []);

  const sendPageReport = () => {
    let collectAgent = getColectAgent();
    if (collectAgent) {
      let now = moment().utc().format("yyyyMMDDHHmmss");

      let productCodes;
      let productTypes;
      let totalCount = showedPurchaseHistory.length;

      productCodes = showedPurchaseHistory
        .map((item) => item.productcode)
        .join(";");
      productTypes = showedPurchaseHistory
        .map((item) => item.producttype)
        .join(";");

      let pageReportObject = {
        eventtype: "E_PAGE",
        eventid: "E_PAGE_IN",
        eventtime: now,
        lastpageid: reportingPageEvent.E_PAGE_MENUESETTING,
        currentpageid: reportingPageEvent.E_PAGE_PURCHASEHISTORY,
        currententryid: "",
        actiontime: now,
        result: "0",
        errorMsg: "no error",
        reseverse1: productCodes,
        reseverse2: productTypes,
        reseverse3: totalCount,
        reseverse7: window.location.origin
      };
    }
  };

  useEffect(() => {
    showedPurchaseHistory?.length && sendPageReport();
  }, [showedPurchaseHistory]);

  const renderedItems = showedPurchaseHistory?.map((item) => {
    return (
      <li key={`${item?.producttype}-${item?.endtime}-${item?.programcode}`}>
        <div className="record-info-box">
          <label>{item?.contentname}</label>
          <div className="info-row">
            <span className="span">
              {moment(item?.begintime, "DD/MM/yyyy HH:mm:ss").format(
                "DD.MM.yyyy HH:mm"
              )}
            </span>
            <span className="dot"></span>
            <span className="span">
              {moment(item?.endtime, "DD/MM/yyyy HH:mm:ss").format(
                "DD.MM.yyyy HH:mm"
              )}
            </span>
          </div>
        </div>
        <label style={{ color: "#FFFFFF" }}>
          {item?.price / parseInt(userFrameConfig?.Fee_Rate)} EUR
        </label>
      </li>
    );
  });

  return (
    <div className="settings-container">
      <div
        className="settings-icon"
        onClick={() => {
          history.push(`/dashboard/settings`);
        }}
      >
        <ReturnIcon />
        <label>{localizationStrings.purchaseHistory}</label>
      </div>
      <div style={{ position: "relative" }}>
        <div className="select-box-row">
          <div
            ref={option2Ref}
            className={`settings-select-box ${showDateFilter ? "opened" : ""}`}
            onClick={() => setShowDateFilter(!showDateFilter)}
          >
            <span>{selectedDate?.label}</span>
            <div className="icon arrow-down"></div>
            <div
              className={`primary-select-box-options ${
                showDateFilter ? "show" : ""
              }`}
              style={{ top: "3.5vw" }}
            >
              {filterDateItems?.map((item) => {
                return (
                  <div
                    className="option"
                    onClick={() => {
                      setSelectedDate(item);
                      filterPurchaseData(item.date);
                    }}
                  >
                    <div
                      className={`option-text ${
                        selectedDate?.id === item.id ? "selected" : ""
                      }`}
                    >
                      {item?.label}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <ul className="list" style={{ width: "100%" }}>
          {renderedItems}
        </ul>
      </div>
    </div>
  );
};

export default PurchaseHistory;
