import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import AudioSubIcon from "../../../assets/icons/svg/AudioSubIcon";
import localizationStrings from "../../../statics/localization";
import { setUserLanguagePreferences } from "../../../api/config/configApi";
import { changePlayerGradient } from "../../../statics/config";
import useOutsideClick from "../../../utils/useOutsideClick";
import { isSafari } from "react-device-detect";
import SubtitleIcon from "../../../assets/icons/svg/SubtitleIcon";

const TrackControl = ({
  player,
  textTracks,
  audioTracks,
  isFullscreen,
  liveui,
  smptePlayer
}) => {
  const [selectedTextTrack, setSelectedTextTrack] = useState(null);
  const [selectedAudioTrack, setSelectedAudioTrack] = useState(null);
  const trackButtonRef = useRef(null);
  const trackEl = useRef(null);
  const sLang = useRef(null); //subtitle language
  const aLang = useRef(null); // audio language
  const itemRef = useRef(null);
  let rightPosition = 0;
  const controlBarElRef = useRef(null);
  const isOpen = useRef(false);

  useOutsideClick(itemRef, () => {
    removeOptions();
  });

  useEffect(() => {
    if (textTracks?.length) {
      sLang.current = localStorage.getItem("subtitleLang");
      if (trackEl.current) removeOptions();
      setDefaultTextTrack();
    }
  }, [textTracks]);

  useEffect(() => {
    if (audioTracks?.length) {
      aLang.current = localStorage.getItem("audioLang");
      if (trackEl.current) removeOptions();
      setDefaultAudioTrack();
    }
  }, [audioTracks]);

  useEffect(() => {
    if (trackEl.current && (selectedTextTrack || selectedAudioTrack)) {
      calcRightPositionOfEl();
    }
  }, [selectedAudioTrack, selectedTextTrack]);

  useEffect(() => {
    return () => {
      removeOptions();
    };
  }, []);

  useEffect(() => {
    if (player) {
      controlBarElRef.current = player.getChild("controlBar").el();
    }
  }, [player]);

  const createOptions = () => {
    trackEl.current = document.createElement("div");
    trackEl.current.classList.add("items-wrapper");
    trackEl.current.setAttribute("id", "vod-player-tracks");
    controlBarElRef.current.insertBefore(
      trackEl.current,
      controlBarElRef.current.childNodes[0]
    );
    calcRightPositionOfEl();
    changePlayerGradient(true);
  };

  const calcRightPositionOfEl = () => {
    let playerPosition = player.el().getBoundingClientRect();
    let playerRightOffset =
      window.innerWidth - playerPosition.left - playerPosition.width;
    let buttonPosition = trackButtonRef.current.getBoundingClientRect();
    let buttonRightOffset =
      window.innerWidth - buttonPosition.left - buttonPosition.width;
    rightPosition = buttonRightOffset - playerRightOffset;
    renderTrackBox(rightPosition);
  };

  const setDefaultTextTrack = () => {
    let subtitleLang = sLang.current;

    if (subtitleLang === "tur") {
      subtitleLang = "tr";
    } else if (subtitleLang === "eng") {
      subtitleLang = "en";
    }
    if (subtitleLang) {
      let track = textTracks.find((item) => item.language == subtitleLang);
      if (track) {
        changeSubtitle(track, true);
      } else {
        offSubtitles();
      }
    } else {
      offSubtitles();
      setSelectedTextTrack(null);
    }
  };

  const setDefaultAudioTrack = () => {
    let audioLang = aLang.current;

    if (audioLang === "tur") {
      audioLang = "tr";
    } else if (audioLang === "eng") {
      audioLang = "en";
    } else if (audioLang === "ori") {
      audioLang = "un";
    }
    if (audioLang) {
      let track = audioTracks.find((item) => item.language == audioLang);
      if (track) {
        changeAudio(track, true);
      } else {
        setSelectedAudioTrack(null);
      }
    } else {
      if (audioTracks?.length) {
        //setSelectedAudioTrack(audioTracks[0]);
        changeAudio(audioTracks[0], true);
      } else {
        setSelectedAudioTrack(null);
      }
    }
  };

  const changeSubtitle = (track, fromSet = false) => {
    textTracks.map((item) => {
      item.mode =
        item.id === track.id ? "showing" : liveui ? "hidden" : "disabled";
    });
    setSelectedTextTrack(track);
    if (!fromSet) {
      let lang = track.language;
      if (lang === "tr") {
        lang = "tur";
      } else if (lang === "en") {
        lang = "eng";
      }

      localStorage.setItem("subtitleLang", lang);
      setLanguagePreferences(lang, localStorage.getItem("audioLang"));
      sLang.current = lang;
    }
  };

  const changeAudio = (track, fromSet = false) => {
    audioTracks.map((item) => {
      item.enabled = item.language == track.language;
    });
    setSelectedAudioTrack(track);

    if (!fromSet) {
      let lang = track.language;
      if (lang === "tr") {
        lang = "tur";
      } else if (lang === "en") {
        lang = "eng";
      } else if (lang === "un") {
        lang = "ori";
      }

      localStorage.setItem("audioLang", lang);
      setLanguagePreferences(localStorage.getItem("subtitleLang"), lang);
      aLang.current = lang;
    }
  };

  const setLanguagePreferences = async (subtitleLang, audioLang) => {
    let obj = `{"lng":"${localStorage.getItem(
      "language"
    )}|${audioLang}|${subtitleLang}"}`;

    const { data } = await setUserLanguagePreferences(obj);
  };

  const offSubtitles = () => {
    if (isSafari && liveui) {
      smptePlayer.setCurrentTextTrack(-1);
    } else {
      textTracks.map((item) => (item.mode = "hidden"));
    }
    setSelectedTextTrack(null);
    localStorage.setItem("subtitleLang", "off");
    setLanguagePreferences("off", localStorage.getItem("audioLang"));
  };

  const renderTrackBox = (rightPosition) => {
    const renderedTrackItems = textTracks?.map((track, index) => {
      return track.kind === "subtitles" ||
        track.kind === "captions" ||
        isSafari ? (
        <div
          key={index}
          className="player-option-item"
          onClick={() => changeSubtitle(track)}
        >
          <label
            className={`player-option-label ${
              track.language === selectedTextTrack?.language ? "selected" : ""
            }`}
          >
            {" "}
            {isSafari
              ? track.language === "tr"
                ? localizationStrings.turkish
                : track.language === "en"
                ? localizationStrings.english
                : track.language === "tng"
                ? localizationStrings.descriptive
                : track.language
              : track.label === "tr"
              ? localizationStrings.turkish
              : track.label === "en"
              ? localizationStrings.english
              : track.label === "tng"
              ? localizationStrings.descriptive
              : track.label}
          </label>
        </div>
      ) : null;
    });

    const renderedAudioItems = audioTracks?.map((track, index) => {
      return (
        <div
          key={index}
          className="player-option-item"
          onClick={() => changeAudio(track)}
        >
          <label
            className={`player-option-label ${
              track.language === selectedAudioTrack?.language ? "selected" : ""
            }`}
          >
            {track.language === "un" || track.label === "main"
              ? localizationStrings.audioOriginal
              : track.language === "tr"
              ? localizationStrings.turkish
              : track.language === "en"
              ? localizationStrings.english
              : track.language === "ar"
              ? localizationStrings.arabic
              : track.language == "tng"
              ? localizationStrings.descriptive
              : track.language}
          </label>
        </div>
      );
    });

    ReactDOM.render(
      <div
        ref={itemRef}
        className="box-wrapper"
        style={{
          marginRight: isFullscreen
            ? `calc(${rightPosition - 135}px - 8em)`
            : ""
        }}
      >
        <div className="box">
          <div className="player-option-item">
            <label className="player-option-label title">
              {localizationStrings.subtitle}
            </label>
          </div>
          <div className="player-option-item" onClick={() => offSubtitles()}>
            <label
              className={`player-option-label ${
                !selectedTextTrack ? "selected" : ""
              }`}
            >
              {localizationStrings.off}{" "}
            </label>
          </div>
          {renderedTrackItems}
        </div>

        <div className="box">
          <div className="player-option-item">
            <label className="player-option-label title">
              {localizationStrings.dubbing}
            </label>
          </div>
          {renderedAudioItems}
        </div>
      </div>,
      document.getElementById("vod-player-tracks")
    );
  };

  const removeOptions = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("vod-player-tracks")
    );
    trackEl.current && trackEl.current.remove();
    trackEl.current = null;
    changePlayerGradient(false);
  };

  // isQualityOpen && trackEl.current && removeOptions();

  return (
    <>
      {isFullscreen ? (
        <button
          className="control-button"
          ref={trackButtonRef}
          style={{
            //display: isFullscreen ? "flex" : "none"
            marginLeft: isFullscreen ? "1.2vw" : null
          }}
          title={localizationStrings.playerOptions.track}
          onClick={() => {
            if (!isOpen.current) {
              createOptions();
            }
            isOpen.current = !isOpen.current;
          }}
        >
          <SubtitleIcon />
        </button>
      ) : null}
    </>
  );
};

export default TrackControl;
