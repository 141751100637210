import React, { useState, useRef, useEffect } from "react";
import Chatbot, { createChatBotMessage } from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import "./ChatBotComponent.scss";

const botName = "Cagatay";

const config = {
  botName: botName,
  initialMessages: [
    createChatBotMessage(`Merhaba ben ${botName}`),
    createChatBotMessage("Size yardımcı olmak için buradayım", {
      withAvatar: true,
      delay: 500,
      widget: "overview"
    })
  ],
  customStyles: {
    chatButton: {
      backgroundColor: "#0c1927"
    },
    placeholderText: "Merhaba, bir mesaj yazın..."
  },
  customComponents: {
    botAvatar: (props) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
        className="appChatbotButtonIcon_Lq7z"
        height={25}
        width={25}
        style={{ marginRight: "10px" }}
        fill="#0c1927"
        {...props}
      >
        <path d="M192 408h64v-48h-64zm384-216h-32a96 96 0 00-96-96H344V24a24 24 0 00-48 0v72H192a96 96 0 00-96 96H64a48 48 0 00-48 48v128a48 48 0 0048 48h32a96 96 0 0096 96h256a96 96 0 0096-96h32a48 48 0 0048-48V240a48 48 0 00-48-48zM96 368H64V240h32zm400 48a48.14 48.14 0 01-48 48H192a48.14 48.14 0 01-48-48V192a48 48 0 0148-48h256a48 48 0 0148 48zm80-48h-32V240h32zM240 208a48 48 0 1048 48 47.996 47.996 0 00-48-48zm160 0a48 48 0 1048 48 47.996 47.996 0 00-48-48zm-16 200h64v-48h-64zm-96 0h64v-48h-64z"></path>
      </svg>
    ),
    userAvatar: (props) => (
      <div className="react-chatbot-kit-user-avatar-container">
        <img
          width={40}
          height={40}
          alt="profile avatar"
          className="profile-logo"
          src="/profilePosters/blueshirtmale.png"
          {...props}
        />
      </div>
    ),
    header: () => (
      <div
        style={{
          backgroundColor: "#0c1927",
          padding: "10px",
          borderRadius: "5px 5px 0 0",
          color: "white",
          textAlign: "center"
        }}
      >
        {botName}
      </div>
    )
  }
};

const ChatbotComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const chatWindowRef = useRef(null);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  });

  return (
    <div>
      {/* Chatbot aç/kapa butonu */}
      <button
        onClick={toggleChat}
        style={{
          backgroundColor: "#0c1927",
          border: "none",
          borderRadius: "25px",
          bottom: "15px",
          height: "50px",
          position: "fixed",
          right: "40px",
          width: "50px",
          zIndex: 6
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
          className="appChatbotButtonIcon_Lq7z"
          height={25}
          width={25}
          fill="#fff"
        >
          <path d="M192 408h64v-48h-64zm384-216h-32a96 96 0 00-96-96H344V24a24 24 0 00-48 0v72H192a96 96 0 00-96 96H64a48 48 0 00-48 48v128a48 48 0 0048 48h32a96 96 0 0096 96h256a96 96 0 0096-96h32a48 48 0 0048-48V240a48 48 0 00-48-48zM96 368H64V240h32zm400 48a48.14 48.14 0 01-48 48H192a48.14 48.14 0 01-48-48V192a48 48 0 0148-48h256a48 48 0 0148 48zm80-48h-32V240h32zM240 208a48 48 0 1048 48 47.996 47.996 0 00-48-48zm160 0a48 48 0 1048 48 47.996 47.996 0 00-48-48zm-16 200h64v-48h-64zm-96 0h64v-48h-64z"></path>
        </svg>
      </button>

      {/* Chatbot ekranı */}
      {isOpen && (
        <div
          style={{
            position: "fixed",
            bottom: "70px",
            right: "20px",
            zIndex: 1000,
            width: "350px",
            height: "500px",
            borderRadius: "10px",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
            overflow: "hidden"
          }}
        >
          <div ref={chatWindowRef} style={{ height: "100%" }}>
            <Chatbot
              placeholderText="Merhaba, bir mesaj yazın..."
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatbotComponent;
