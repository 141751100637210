import React from "react";

const feedback = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57.775"
      height="45.507"
      viewBox="0 0 57.775 45.507"
    >
      <g id="Group_993" data-name="Group 993" transform="translate(0 0)">
        <path
          id="Fill_1"
          data-name="Fill 1"
          d="M27.4,0H18.365a1.022,1.022,0,1,0,0,2.043H27.4A4.069,4.069,0,0,1,31.46,6.108V18.5A4.069,4.069,0,0,1,27.4,22.559H24.962a1.022,1.022,0,0,0-1.022,1.022v5.053l-5.776-5.776a1.022,1.022,0,0,0-.722-.3H6.108A4.069,4.069,0,0,1,2.043,18.5,1.022,1.022,0,0,0,0,18.5,6.115,6.115,0,0,0,6.108,24.6H17.019l7.221,7.221a1.022,1.022,0,0,0,1.744-.722V24.6H27.4A6.115,6.115,0,0,0,33.5,18.5V6.108A6.115,6.115,0,0,0,27.4,0"
          transform="translate(24.271 13.384)"
          fill="#fff"
        />
        <path
          id="Fill_4"
          data-name="Fill 4"
          d="M9.99,37.918A1.024,1.024,0,0,1,8.969,36.9V28.949H7.087A7.1,7.1,0,0,1,0,21.862V7.088A7.1,7.1,0,0,1,7.087,0h25.39a7.1,7.1,0,0,1,7.088,7.088V21.862a7.1,7.1,0,0,1-7.088,7.087h-13.1l-8.669,8.669A1.029,1.029,0,0,1,9.99,37.918ZM7.087,2.044A5.05,5.05,0,0,0,2.043,7.088V21.862a5.05,5.05,0,0,0,5.044,5.044h2.9a1.023,1.023,0,0,1,1.021,1.021v6.5l7.224-7.224a1.029,1.029,0,0,1,.723-.3H32.477a5.049,5.049,0,0,0,5.044-5.044V7.088a5.049,5.049,0,0,0-5.044-5.044Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default feedback;
