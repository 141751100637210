import React, { useContext, useEffect, useRef, useState } from "react";
import { getSearchKeyword, getSearchVods } from "../../api/vod/vod";
import { useHistory, useParams } from "react-router-dom";
import { SessionContext } from "../../context/SessionContext";
import localizationStrings from "../../statics/localization";
import InfiniteScroll from "react-infinite-scroll-component";
import { sendCustomReport } from "../../utils/reporting/customReporting";
import { reportingCustomEvent } from "../../statics/reportEventId";
import ContentGrid from "../contentGrid";
import ModalRouting from "../modalRouting";
import { getSearchReccomend } from "../../api/common/commonApi";
import VodGrid from "../vodGrid/VodGrid";
import SearchIcon from "../../assets/icons/svg/SearchIcon";
import SearchCloseIcon from "../../assets/icons/svg/SearchCloseIcon";

const Search = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [suggestedVods, setSuggestedVods] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [showText, setShowText] = useState(true);
  const [hasNoData, setHasNoData] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { userInfo, userFrameConfig, isChildProfile, setPath } =
    useContext(SessionContext);
  const pageRef = useRef(1);
  const itemsRef = useRef([]);
  const { term } = useParams();
  const posterPath = useRef(null);
  const elRef = useRef(null);
  const history = useHistory();
  const timeoutRef = useRef(null);
  const inputRef = useRef(null);
  let pos = { top: 0, left: 0, x: 0, y: 0 };
  const filterItems = [
    {
      id: "0",
      contenttype: "0|1|2|3|5",
      label: localizationStrings.searchFilters.all,
      subtype: "1|14"
    }
    /*   {
      id: "1",
      contenttype: "0",
      label: localizationStrings.searchFilters.movie,
      subtype: "1"
    },
    {
      id: "2",
      contenttype: "0",
      label: localizationStrings.searchFilters.series,
      subtype: "14"
    },
    {
      id: "3",
      contenttype: "5",
      label: localizationStrings.searchFilters.cuTv,
      subtype: "1|14"
    },
    {
      id: "4",
      contenttype: "1|3",
      label: localizationStrings.searchFilters.program,
      subtype: "1|14"
    },
    {
      id: "5",
      contenttype: "2",
      label: localizationStrings.searchFilters.tvChannel,
      subtype: null
    } */
  ];

  //Önerilenler
  /*   const getUserLikeData = async () => {
    const data = await getSearchReccomend(userFrameConfig.Search_Column);
    if (data.data.returncode == 0) {
      setShowText(true);
      posterPath.current = data.data.posterpath;
      setSuggestedVods(data.data.data);
      // setPosterPath(data.data.posterpath);
    }
  }; */

  const search = async (contentTypes, subTypes, notScroll = false) => {
    const { data } = await getSearchVods(searchTerm);

    /*   console.log({ data }); */

    if (data.channels && data.contents) {
      const mergeArray = [...data?.channels, ...data?.contents];

      setSearchResult(mergeArray);

      setShowText(false);
    } else {
      setSearchResult([]);
    }

    /*  if (pageRef.current >= 4) {
      return false;
    }
    getKeywords();
    if (!notScroll) {
      pageRef.current++;
    } */
    /*  let type = contentTypes ? contentTypes : selectedFilter?.contenttype;
    let subType = subTypes ? subTypes : selectedFilter?.subtype;

    let columncodes;
    if (isChildProfile) {
      columncodes = `${userFrameConfig.ChildMode_TV_Column_All}|${userFrameConfig.Child_Column}`;
    } else {
      columncodes = userFrameConfig.Search_Column;
    } */
    /*   const data = await getSearchResult(
      term,
      userInfo?.userlevel,
      type,
      subType,
      pageRef.current,
      columncodes
    ); */
    /*  if (data.data.returncode === "0") {
      setShowText(false);
      if (data.data.totalpage == pageRef.current) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
      if (!notScroll) {
        itemsRef.current = itemsRef.current.concat(data.data.itemlist);
      } else {
        itemsRef.current = data.data.itemlist;
      }
      setSearchResult(itemsRef.current);
      setHasNoData(false);
    } else {
      if (data.data.returncode === "1") {
        setHasNoData(true);
      }
      if (pageRef.current <= 1) {
        setSearchResult([]);
      }
      setHasMore(false);
    }

    sendCustomReport(
      reportingCustomEvent.E_CUSTOM_SEARCH,
      term,
      data.data.returncode,
      data.data.errormsg
    ); */
  };

  /* const getKeywords = async () => {
    const { data } = await getSearchKeyword(term);
    if (data.returncode === "0") {
      setKeywords(data.data);
    }
  }; */

  const renderedFilterItems = filterItems.map((item) => {
    return (
      <FilterItem
        key={item.id}
        item={item}
        selectedFilterId={selectedFilter?.id}
        setSelected={(val) => setSelectedFilter(val)}
      />
    );
  });

  useEffect(() => {
    if (term && term.split(" ").join("").length) {
      if (!term.includes("voddetail")) {
        setPath(`/dashboard/search/${term}`);
        if (!selectedFilter) {
          setSelectedFilter(filterItems[0]);
        } else {
          search(selectedFilter.contenttype, selectedFilter.subtype, true);
        }
      }
    } else {
      setSearchTerm("");
      history.push("/dashboard/search");
      inputRef.current.focus();
      setHasNoData(false);
      if (!suggestedVods.length) {
        /* getUserLikeData(); */
      } else {
        setShowText(true);
      }
    }
  }, [term]);

  useEffect(() => {
    if (selectedFilter) {
      pageRef.current = 1;
      search(selectedFilter.contenttype, selectedFilter.subtype, true);
    }
  }, [selectedFilter]);

  useEffect(() => {
    setSearchTerm(term);
    elRef.current = document.getElementById("keywordWrapper");
    //setPath("/dashboard/search");
    //if (!term) getUserLikeData();
  }, []);

  const mouseDownHandler = function (e) {
    pos = {
      // The current scroll
      left: elRef.current.scrollLeft,
      top: elRef.current.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY
    };

    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
  };

  const mouseMoveHandler = function (e) {
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    // Scroll the element
    elRef.current.scrollLeft = pos.left - dx;
  };

  const mouseUpHandler = function () {
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);

    elRef.current.style.cursor = "grab";
    elRef.current.style.removeProperty("user-select");
  };

  return (
    <div className="search-container">
      <ModalRouting />

      <div className="search-input-wrapper">
        <input
          ref={inputRef}
          className="search-input"
          value={searchTerm}
          onInput={(e) => {
            let val = e.target.value;
            setSearchTerm(val);
            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
              history.push(`/dashboard/search/${val}`);
            }, 500);
          }}
          autoFocus={true}
          placeholder={localizationStrings.searchSuggestText}
        />
        <span className="icon icon-left">
          <SearchIcon />
        </span>

        <span
          className="icon icon-right"
          style={{ display: !searchTerm ? "none" : "" }}
          onClick={() => {
            setSearchTerm("");
            history.push("/dashboard/search");
            inputRef.current.focus();
            setHasNoData(false);
            if (!suggestedVods.length) {
              /* getUserLikeData(); */
            } else {
              setShowText(true);
            }
          }}
        >
          <SearchCloseIcon />
        </span>
      </div>

      <div
        className="category-row"
        style={{
          marginTop: "2vw",
          marginBottom: "0",
          display: showText ? "none" : ""
        }}
      >
        <div className="category-wrapper" style={{ justifyContent: "center" }}>
          {renderedFilterItems}
        </div>
      </div>

      <div
        className="keyword-row"
        style={{
          display: showText || !keywords?.length ? "none" : ""
        }}
      >
        <div className="title">{localizationStrings.relatedVods}:</div>
        <div
          id="keywordWrapper"
          className="keyword-wrapper"
          onMouseDown={(e) => mouseDownHandler(e)}
        >
          {keywords.map((item) => {
            return (
              <div
                key={item.contentname}
                onClick={() => {
                  setSearchTerm(item.contentname);
                  setSelectedFilter(filterItems[0]);
                  history.push(`/dashboard/search/${item.contentname}`);
                }}
                className="keyword-item"
              >
                {item.contentname}
              </div>
            );
          })}
        </div>
      </div>
      {showText && !hasNoData ? (
        <div className="sug-text">
          {/* {localizationStrings.searchSuggestions} */}
        </div>
      ) : null}
      {!hasNoData ? (
        <div>
          {showText ? (
            <ContentGrid
              items={suggestedVods}
              posterPosition={7}
              posterPath={posterPath.current}
            />
          ) : (
            <InfiniteScroll
              dataLength={searchResult?.length}
              next={search}
              hasMore={hasMore}
            >
              <VodGrid
                items={searchResult}
                posterPosition={7}
                posterPath={posterPath.current}
                fromSearch={true}
              />
            </InfiniteScroll>
          )}
        </div>
      ) : (
        <div className="no-data-container">
          <div className="wrapper">
            <div className="text">
              {localizationStrings.formatString(
                localizationStrings.noSearchResult,
                { term: term }
              )}
            </div>
            <div className="title">{localizationStrings.searchSuggestions}</div>
            <div className="suggestion-item">{localizationStrings.sugg1}</div>
            <div className="suggestion-item">{localizationStrings.sugg3}</div>
            <div className="suggestion-item">{localizationStrings.sugg4}</div>
          </div>
        </div>
      )}
    </div>
  );
};

const FilterItem = ({ item, selectedFilterId, setSelected }) => {
  return (
    <div
      key={item?.columncode}
      className={`category-item ${
        selectedFilterId === item?.id ? "selected" : ""
      }`}
      onClick={() => setSelected(item)}
    >
      {item?.label}
    </div>
  );
};

export default Search;
