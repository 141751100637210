import * as mock from "../mockup/mockup";
import axios from "axios";
import { isEdge, isIE, isSafari } from "react-device-detect";
import { sendCustomFavoriteReport } from "../../utils/reporting/customReporting";
import { apiEndPointConstant } from "../../statics/constants";

const getVodById = async (vodId) => {
  return await axios.get("/content/" + vodId);
};

const toggleLikeById = async (vodId) => {
  return await axios.post(`/interact/like/` + vodId);
};

const toggleDislikeById = async (vodId) => {
  return await axios.post(`/interact/dislike/` + vodId);
};

const toggleFavById = async (vodId) => {
  return await axios.post(`/interact/watchlist/` + vodId);
};

const getVodPlayUrlById = async (vodId, currentTime) => {
  return await axios.post("/content/" + vodId + "/watch", currentTime, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};

const getTrailerPlayUrlById = async (vodId) => {
  return await axios.post("/content/" + vodId + "/trailer");
};

const getSearchVods = async (keyword) => {
  return await axios.get("/search", { params: { keyword: keyword } });
};

const sendPayment = async (vodId, productId) => {
  return await axios.get("/rentpurchase/" + vodId + "/payment/" + productId);
};

const getMainboards = async (bannerPosition) => {
  let apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.get(`${apiEndpoint}/sdk_getbannerlist.jsp`, {
    params: { bannerposition: bannerPosition }
  });
};

const getMainboardsMockup = async () => {
  return await new Promise((resolve, reject) => {
    setTimeout(() => resolve(mock.mainboards), 300);
  });
};

const getVodsByLabel = async (id, page, size) => {
  return await axios.get("/label/" + id, {
    params: { page: page, size: size }
  });
};

const getRecommendData = async (columnCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/getrecommenddata.jsp`, {
    params: {
      columncode: columnCode,
      contenttype: "1,14",
      pageno: 1,
      numberpage: 200
    }
  });
};

const getFavList = async (favType, columnCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.post(
    `${apiEndpoint}/sdk_getfullfavoritelist.jsp`,
    `favoritetype=${favType}&vodcolumncode=${columnCode}`
  );
};

const getBookmarkedContents = async (columnCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getfullbookmarklist.jsp`, {
    params: {
      vodcolumncode: columnCode,
      bookmarktype: "1,4,19"
    }
  });
};

const getVodDetail = async (programCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getvoddetail.jsp`, {
    params: {
      programcode: programCode,
      isquerytrailer: 1,
      isquerybookmark: 1,
      ischecksubscribe: 1
    }
  });
};

const getSerieDetail = async (programCode, contentCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getvodinfo.jsp`, {
    params: {
      programcode: programCode,
      contentcode: contentCode
    }
  });
};

const getSeasons = async (programCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getserieschildlist.jsp`, {
    params: {
      seriesprogramcode: programCode,
      seriestype: 3,
      pageno: 1,
      numperpage: 500
    }
  });
};

const getEpisodes = async (programCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getserieschildlist.jsp`, {
    params: {
      seriesprogramcode: programCode,
      seriestype: 2,
      pageno: 1,
      numperpage: 500
    }
  });
};

const getVodPlayUrl = async (programCode, columnCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getvodurl.jsp`, {
    params: {
      programcode: programCode,
      columncode: columnCode,
      urlredirect: 1
    }
  });
};

const getSearchKeyword = async (term) => {
  let apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.post(
    `${apiEndpoint}/sdk_getkeywordsearch.jsp`,
    `condition=${decodeURIComponent(
      term
    )}&conditiontype=16|17|18|19&searchtype=2&sorttype=0&pageno=1&numperpage=10`
  );
};

const getSearchResult = async (
  term,
  userLevel,
  contentTypes,
  subType,
  page,
  columnCodes
) => {
  let apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.post(
    `${apiEndpoint}/getsearchdata.jsp`,
    `conditiontype=16|17|18|19|20|21|22&condition=${encodeURIComponent(
      encodeURIComponent(term)
    )}&contenttype=${contentTypes}${
      subType ? `&subtype=${subType}&seriestype=1|3` : ""
    }&filtertype=0|1|2&languagetype=tur|eng&ratingid=${userLevel}&pageno=${page}&numperpage=20&mediaservices=-1|2&columncode=${columnCodes}&contentcode=${columnCodes}`
  );
};

const getUserRatingScore = async (contentCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getuserpersonalstaring.jsp`, {
    params: {
      contentcode: contentCode
    }
  });
};

const doRatingVod = async (contentCode, contentType, rating, oldRating) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/dostarrating.jsp`, {
    params: {
      contentcode: contentCode,
      contenttype: contentType,
      starrating: rating,
      oldstarrating: oldRating
    }
  });
};

const getBannerVodDetail = async (programCodes) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getvodinfo.jsp`, {
    params: {
      programcode: programCodes
    }
  });
};

const getUserTvodPurchaseList = async () => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_gettvodpurchaselist.jsp`);
};

const getUserEstPurchaseList = async () => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getestpurchaselist.jsp`);
};

const sendVideoComplated = async (contentCode, columnCode, programCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_addcomplete.jsp`, {
    params: {
      contentcode: contentCode,
      columncode: columnCode,
      contentinfo: programCode
    }
  });
};

const getWatchItAgainVodList = async (vodColumnCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getuserviewcompletelist.jsp`, {
    params: {
      vodcolumncode: vodColumnCode
    }
  });
};

const getBundleVods = async (productCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  var sessiondId = localStorage.getItem("sessionid");
  return await axios.get(`${apiEndpoint}/sdk_getmoviebyproduct.jsp`, {
    params: {
      productcode: productCode
    }
  });
};

const getPreviewUrl = async (programCode, columnCode) => {
  var apiEndpoint = localStorage.getItem("interfaceurl");
  return await axios.get(`${apiEndpoint}/sdk_getvodpreviewurl.jsp`, {
    params: {
      programcode: programCode,
      columncode: columnCode,
      urlredirect: "1"
    }
  });
};

export {
  getVodById,
  toggleLikeById,
  toggleDislikeById,
  toggleFavById,
  getVodPlayUrlById,
  getTrailerPlayUrlById,
  getSearchVods,
  getMainboards,
  sendPayment,
  getSearchResult,
  getVodsByLabel,
  getRecommendData,
  getFavList,
  getBookmarkedContents,
  getVodDetail,
  getVodPlayUrl,
  getSerieDetail,
  getEpisodes,
  getSeasons,
  getSearchKeyword,
  getMainboardsMockup,
  getUserRatingScore,
  doRatingVod,
  getBannerVodDetail,
  getUserEstPurchaseList,
  getUserTvodPurchaseList,
  sendVideoComplated,
  getWatchItAgainVodList,
  getBundleVods,
  getPreviewUrl
};
